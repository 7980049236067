import { getBaseColor } from "compass-commons";
import GroupInfoSearchResult from "../models/incidents/GroupInfoSearchResult";

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
export const mapGroupInfoFromAPI = (object: any) => {
  let groupedInfoResult: GroupInfoSearchResult;

  if (object && "elements" in object && object.elements?.length > 0) {
    groupedInfoResult = new GroupInfoSearchResult();
    groupedInfoResult.children = [];
    for (const entry of object.elements) {
      if ("count" in entry && entry.count > 0) {
        // only put count if is last one
        const childObj = new GroupInfoSearchResult(entry);
        childObj.parentId = groupedInfoResult.id;
        groupedInfoResult.children.push(childObj);
      }
    }
  } else if ("category" in object) {
    groupedInfoResult = new GroupInfoSearchResult(object);
  }
  return groupedInfoResult;
};

const convertHexToRGBA = (hexCode, opacity = 1) => {
  let hex = hexCode.replace("#", "");

  if (hex.length === 3) {
    hex = `${hex[0]}${hex[0]}${hex[1]}${hex[1]}${hex[2]}${hex[2]}`;
  }

  const r = parseInt(hex.substring(0, 2), 16);
  const g = parseInt(hex.substring(2, 4), 16);
  const b = parseInt(hex.substring(4, 6), 16);

  return `rgba(${r},${g},${b},${opacity || 1})`;
};

const reduceColorTransparency = (color: string, opacity = 0.65): string => {
  let updatedColor = color;
  if (updatedColor != null) {
    if (color.includes("#")) {
      updatedColor = convertHexToRGBA(color, opacity);
    } else if (color.includes("rgba")) {
      const rgbaSplit = color.split(",");
      rgbaSplit[rgbaSplit.length] = `${opacity || 1})`;
      updatedColor = rgbaSplit.join(",");
    }
  } else {
    updatedColor = "white";
  }
  return updatedColor;
};

/**
 * Function that fills color in group info search result children recursively
 * and with transparencies
 */
const mapChildrenColors = (
  groupInfo: GroupInfoSearchResult,
  color: string,
  opacityValues: number[] = [],
  opacityIndex = 0
) => {
  const dataCopy = groupInfo;
  const opacity =
    opacityIndex && opacityValues?.length > opacityIndex
      ? opacityValues[opacityIndex]
      : null;
  dataCopy.color = reduceColorTransparency(color, opacity);
  dataCopy?.children?.map((child) =>
    mapChildrenColors(child, color, opacityValues, opacityIndex + 1)
  );
};

function getTreeDepth(groupInfoSearchResult: GroupInfoSearchResult): number {
  if (groupInfoSearchResult?.children?.length > 0) {
    const depths = groupInfoSearchResult.children.map(getTreeDepth);
    return 1 + Math.max(...depths);
  }
  return 0;
}

function getOpacities(treeDepth: number): number[] {
  const opacities = [];
  const spacing = 0.8 / (treeDepth !== 0 ? treeDepth : 1);
  for (let i = 1; i >= 0.2; i -= spacing) {
    opacities.push(i);
  }
  return opacities;
}

export const mapGroupInfoData = (
  groupInfo: GroupInfoSearchResult
): GroupInfoSearchResult => {
  const mappedCopy = groupInfo;
  if (mappedCopy.parentId == null) {
    mappedCopy.color = "white";
  }
  for (let i = 0; i < mappedCopy?.children.length; i += 1) {
    // First data level should be incidentPriorities
    if (mappedCopy.children[i].children?.length === 0) {
      mappedCopy.children[i].size = mappedCopy?.children[i].count + 1;
    }
    if (mappedCopy?.children[i].category === "incidentPriorities") {
      // incident priorities must be the first ring
      const treeDepth: number = getTreeDepth(mappedCopy);
      const opacities: number[] = getOpacities(treeDepth);
      const newColor = getBaseColor(mappedCopy?.children[i].value);
      mapChildrenColors(mappedCopy.children[i], newColor, opacities, 0);
    } else {
      mappedCopy.children[i].color = "white";
    }
  }
  return mappedCopy;
};
