import React, { useCallback, useEffect, useState } from "react";
import {
  getDateAndTimeFromTimestamp,
  IncidentStep,
  InfoField,
  InfoFieldPhoto,
  InfoFieldTypes,
  MediaRequest,
} from "compass-commons";
import * as styles from "../styles";
import IncidentStepContent from "./IncidentStepContent";
import IncidentStepIcon from "./IncidentStepIcon";
import MediaModal from "../../../commons/mediaModal/MediaModal";
import DataExplorerService from "../../../../services/DataExplorerService";
import StateService from "../../../../services/StateService";
import { useStateContext } from "../../../../contexts/StateContext";
import ImageGallery from "../../../commons/imageGallery/ImageGallery";

const getConnectorStyle = (isSystemStep, indexControl, baseColor, steps) => {
  let style;
  if (isSystemStep) {
    style = styles.systemDashedConnector(baseColor);
  } else {
    style = styles.defaultOperatorConnector(baseColor);
    if (indexControl === 0) {
      style = styles.firstOperatorConnector(baseColor);
      if (indexControl === steps.length - 1) {
        style = styles.firstAndLastOperatorConnector(baseColor);
      }
    } else if (indexControl === steps.length - 1) {
      style = styles.lastOperatorConnector(baseColor);
    }
  }
  return style;
};

const getOverrideItem = (isSystemStep, indexControl, steps) => {
  if (!isSystemStep && indexControl === steps.length - 1) {
    return styles.lastItem();
  }
  return {};
};

const TimelineSteps = (props: {
  steps: Array<IncidentStep>;
  baseColor: string;
  isSystemStep: boolean;
  executionId?: string;
}): JSX.Element => {
  const { steps, baseColor, isSystemStep, executionId } = props;
  let indexControl = -1;

  const [modalShow, setModalShow] = useState(false);
  const [activeModalId, setActiveModalId] = useState("");
  const [mediaFile, setMediaFile] = useState(null);
  const stateService: StateService = useStateContext();
  const { mediaLoading } = stateService;

  const [showGalleryFullScreenMode, setShowGalleryFullScreenMode] =
    useState<boolean>(false);
  const [photoDatas, setPhotoDatas] = useState<InfoFieldPhoto[]>([]);
  // Use to keep tracking what file id we should fetch

  const showModal = useCallback(() => {
    setModalShow(!modalShow);
  }, [modalShow]);

  const activeModal = useCallback(
    (id) => {
      setActiveModalId(id);
    },
    [activeModalId]
  );

  const getMedia = async (file) => {
    try {
      const request: MediaRequest = {
        fileId: file,
      };

      const result = await DataExplorerService.getMedia(request);
      if (result) {
        setMediaFile(result);
        mediaLoading.next({ isLoading: false });
      }
    } catch (error) {
      console.log(error);
      mediaLoading.next({ isLoading: false });
    }
  };

  useEffect(() => {
    setMediaFile(mediaFile);
  }, [mediaFile]);

  const handleGetMedia = (fileId) => {
    mediaLoading.next({ isLoading: true });
    getMedia(fileId);
  };

  const preparePhotos = (step: IncidentStep) => {
    const tempPhotos = [];
    setPhotoDatas(photoDatas.splice(0, photoDatas.length));
    step.genericInfoList.forEach((infof: InfoField) => {
      if (InfoFieldTypes.PHOTO === infof.infoFieldType) {
        tempPhotos.push(infof as InfoFieldPhoto);
      }
    });
    setPhotoDatas(tempPhotos);
  };

  useEffect(() => {
    setShowGalleryFullScreenMode(photoDatas && photoDatas?.length > 0);
  }, [photoDatas]);

  const stepsList = steps?.map((step) => {
    indexControl += 1;
    return (
      <div
        key={indexControl}
        className="timeline-item"
        data-cr={
          isSystemStep ? "timeline-system-step" : "timeline-operator-step"
        }
        style={{ ...getOverrideItem(isSystemStep, indexControl, steps) }}
      >
        <div className="timeline-separator">
          <IncidentStepIcon
            incidentStep={step}
            baseColor={baseColor}
            isSystemStep={isSystemStep}
            onClick={(e) => {
              e.stopPropagation();
              activeModal(step.id);
              if (step.mediaFileList?.length > 0) {
                showModal();
                handleGetMedia(step.mediaFileList[0]?.id);
              }

              if (step.genericInfoList?.length > 0) {
                preparePhotos(step);
              }
            }}
          />
          {step.genericInfoList?.length > 0 &&
          showGalleryFullScreenMode &&
          activeModalId === step.id ? (
            <ImageGallery
              isOpen={showGalleryFullScreenMode}
              mediaData={photoDatas}
              onClose={() => {
                setShowGalleryFullScreenMode(false);
                setPhotoDatas([]);
                setActiveModalId("");
              }}
            />
          ) : null}
          <div
            className="timeline-connector"
            style={{
              ...getConnectorStyle(
                isSystemStep,
                indexControl,
                baseColor,
                steps
              ),
            }}
          />
        </div>
        <div className="timeline-content">
          <IncidentStepContent
            incidentStep={step}
            isSystemStep={isSystemStep}
          />
        </div>
        {step.mediaFileList?.length > 0 && (
          <MediaModal
            mediaType={step.mediaFileList[0]?.extension}
            mediaTitle={step.device}
            mediaInfo={getDateAndTimeFromTimestamp(step.creationTimestamp)}
            id={step.id}
            onClose={showModal}
            show={modalShow}
            activeModalId={activeModalId}
            mediaFile={mediaFile}
          />
        )}
      </div>
    );
  });

  return <div data-cr="timeline-steps-div">{stepsList}</div>;
};

export default TimelineSteps;
