// General
import React from "react";
import {
  ReportCartItem,
  ReportCartItemTypeEnum,
  ResultViewEnum,
  useI18n,
} from "compass-commons";
// Components
import AddToReportButton from "../../../commons/addToReportButton/AddToReportButton";
// Styles
import "../../resultsPanel.module.css";
// Store
import { useStateContext } from "../../../../contexts/StateContext";
// Services
import ReportCartService from "../../../../services/ReportCartService";
import StateService from "../../../../services/StateService";
// Errors
import ReportCartItemAlreadyExistsError from "../../../../errors/ReportCartItemAlreadyExistsError";

interface ResultsFooterProps {
  disabled?: boolean;
  searchResult;
  selectedButtonGroup;
  incidentsLimitReached;
}

const ResultsFooter = (footerProps: ResultsFooterProps): JSX.Element => {
  const { disabled, searchResult, selectedButtonGroup, incidentsLimitReached } =
    footerProps;
  const { t: translate } = useI18n();
  const stateService: StateService = useStateContext();
  const {
    filterCriteriaHolder,
    currentReportCart,
    badgeAlreadyHasSelectedItemMessage,
    alertSubject,
  } = stateService;
  const getSpanListFromMap = (map: Map<string, number>): JSX.Element[] => {
    const mapList: JSX.Element[] = [];

    if (map != null) {
      Object.entries(map).forEach((entry) => {
        if (entry.length === 2 && entry[0] !== "total") {
          mapList.push(
            <div key={entry[0]}>
              <span>
                {entry[1]} {entry[0]}
              </span>
              <br />
            </div>
          );
        }
      });
    }

    return mapList;
  };

  const getPriorities = (priorities: Map<string, number>): JSX.Element => {
    return <div className="inner">{getSpanListFromMap(priorities)}</div>;
  };

  const getFilesAndComments = (
    filesAndComments: Map<string, number>
  ): JSX.Element => {
    let total = [0];
    Object.entries(filesAndComments).forEach((entry) => {
      if (entry.length === 2 && entry[0] === "total") {
        total = [];
        total.push(entry[1]);
      }
    });

    return (
      <div className="inner">
        {total[0]} {translate("results.incidents")}
      </div>
    );
  };

  const addToReport = () => {
    const currentCart = currentReportCart.value;
    const reportCartItem = new ReportCartItem();
    reportCartItem.type = ReportCartItemTypeEnum.CRITERIA;
    reportCartItem.filteringCriteria = filterCriteriaHolder.value;

    ReportCartService.addCartItem(currentCart, reportCartItem)
      .then((res) => currentReportCart.next(res))
      .catch((error) => {
        if (error instanceof ReportCartItemAlreadyExistsError) {
          badgeAlreadyHasSelectedItemMessage.next(error.message);
        } else {
          alertSubject.next(translate("results.failedToAddToReport"));
        }
      });
  };

  return (
    <div className="results-footer-main-div">
      {searchResult?.metadata &&
        !disabled &&
        selectedButtonGroup === ResultViewEnum.GRAPHIC && (
          <div className="results-footer-div">
            <div className="results-footer-left">
              <span>
                <b>{searchResult != null ? searchResult.totalRecords : 0}</b>{" "}
                {translate("results.incidents")}
              </span>
            </div>
            <div className="results-footer-middle">
              <span>{translate("results.incidentPriority")}</span>
              {getPriorities(searchResult.metadata.incidentPriorities)}
            </div>
            <div className="results-footer-right">
              <span>{translate("results.withAddedComments")}</span>
              {getFilesAndComments(searchResult.metadata.filesAndComments)}
            </div>
          </div>
        )}
      {searchResult?.incidentList?.length > 0 && !incidentsLimitReached && (
        <div className="results-footer-buttons">
          <AddToReportButton onClick={addToReport} />
        </div>
      )}
    </div>
  );
};

ResultsFooter.defaultProps = {
  disabled: false,
};

export default ResultsFooter;
