import { getUrl, MediaInfo } from "compass-commons";
import { HttpClient } from "compass-shared-services";

const { MEDIA_FILE_MANAGER_PATH } = appConfig;
const httpClient = new HttpClient(appConfig);
const URL_PATHS = {
  GET_MEDIA_FILE: `${MEDIA_FILE_MANAGER_PATH}/media-files/(mediaFileId)`,
  GET_MEDIA_FILE_CONTENT: `${MEDIA_FILE_MANAGER_PATH}/media-files/(mediaFileId)/content`,
};

export default class MediaFileManagerService {
  static async getMediaById(mediaFileId: string): Promise<MediaInfo> {
    const uri = {
      mediaFileId,
    };
    const urlPath = getUrl(URL_PATHS.GET_MEDIA_FILE, uri);
    return httpClient
      .get<MediaInfo>({
        url: urlPath,
      })
      .catch(async (error) => {
        throw Error(error);
      });
  }

  static async getMediaContentById(mediaFileId: string): Promise<any> {
    const uri = {
      mediaFileId,
    };
    const urlPath = getUrl(URL_PATHS.GET_MEDIA_FILE_CONTENT, uri);
    return httpClient
      .get<any>({
        url: urlPath,
        config: {
          responseType: "blob",
        },
      })
      .catch(async (error) => {
        throw Error(error);
      });
  }
}
