// General
import React, { useEffect, useState } from "react";
import {
  ReportCart,
  ReportCartItem,
  ReportCartStateEnum,
} from "compass-commons";
// Components
import { Button } from "dms-lib";
import DescriptionRounded from "@mui/icons-material/DescriptionRounded";
import Badge from "@mui/material/Badge";
import Popper, { PopperPlacementType } from "@mui/material/Popper";
import Fade from "@mui/material/Fade";
import Divider from "@mui/material/Divider";
import { Alert, Fab } from "@mui/material";
// Styles
import "./ReportCartBadge.css";
// Store
import { BehaviorSubject } from "rxjs";
// Services
import ReportCartService from "../../services/ReportCartService";
// Errors
import ReportCartGetCartsError from "../../errors/ReportCartGetCartsError";

interface ReportCartBadgeProps {
  reportCartSubject: BehaviorSubject<ReportCart>;
  badgeErrorMessageSubject: BehaviorSubject<string>;
  onClickCallBack: (isClicked: boolean) => void;
}

const ReportCartBadge = (props: ReportCartBadgeProps): JSX.Element => {
  const { reportCartSubject, badgeErrorMessageSubject, onClickCallBack } =
    props;
  const [reportItemsCount, setReportItemsCount] = useState(
    reportCartSubject?.value?.elementList?.length || 0
  );
  const [anchorEl, setAnchorEl] = useState<HTMLButtonElement | null>(null);
  const [open, setOpen] = useState(false);
  const itemsLimit = 10;
  const [placement, setPlacement] = useState<PopperPlacementType>();
  const [errorMessage, setErrorMessage] = useState(
    badgeErrorMessageSubject?.value
  );
  const [alertIsOpen, setAlertIsOpen] = useState(false);

  useEffect(() => {
    ReportCartService.getCarts(ReportCartStateEnum.ACTIVE)
      .then((res) => {
        if (res && res.length > 0) {
          reportCartSubject.next(res[0]); // TODO there is only one active in the cart list. But this may change in the future.
          setReportItemsCount(res[0]?.getElementCount());
        } else {
          reportCartSubject.next(null);
          setReportItemsCount(0);
        }
      })
      .catch((e) => {
        throw new ReportCartGetCartsError(e);
      });

    const subscription = reportCartSubject?.subscribe((value) => {
      if (value) {
        setReportItemsCount(value.getElementCount());
      }
    });

    const subsBadgeError = badgeErrorMessageSubject?.subscribe((value) => {
      if (value) {
        setErrorMessage(value);
        setAlertIsOpen(true);
        setTimeout(() => {
          setAlertIsOpen(false);
          badgeErrorMessageSubject.next(null);
        }, 4000);
      }
    });

    return function cleanup() {
      subscription?.unsubscribe();
      subsBadgeError?.unsubscribe();
    };
  }, [reportCartSubject, badgeErrorMessageSubject, errorMessage]);

  const handlePopperClick =
    (newPlacement: PopperPlacementType) =>
    (event: React.MouseEvent<HTMLButtonElement>) => {
      setAnchorEl(event.currentTarget);
      setOpen((prev) => placement !== newPlacement || !prev);
      setPlacement(newPlacement);
    };

  const getPopperItems = () => {
    const items: JSX.Element[] = [];

    reportCartSubject?.value?.elementList?.forEach((el: ReportCartItem) => {
      if (items.length < itemsLimit) {
        const div: JSX.Element = (
          <div className="report-cart-item" key={el.position}>
            <span>
              <b>{el.position}</b> - {el.type}
            </span>
            <Divider />
          </div>
        );
        items.push(div);
      }
    });

    return items;
  };

  return (
    <div style={{ display: "flex" }}>
      {alertIsOpen ? (
        <Alert
          style={{ marginRight: "5px" }}
          variant="outlined"
          severity="info"
        >
          {errorMessage}
        </Alert>
      ) : (
        <></>
      )}
      <Popper open={open} anchorEl={anchorEl} placement={placement} transition>
        {({ TransitionProps }) => (
          // eslint-disable-next-line react/jsx-props-no-spreading
          <Fade {...TransitionProps} timeout={350}>
            <div className="cart-popper compass-rounded-corner compass-box-shadow">
              {reportItemsCount > 0 ? (
                <>
                  {getPopperItems()}
                  <div className="cart-popper-button">
                    <Button
                      variant="contained"
                      color="primary"
                      onClick={() => onClickCallBack(true)}
                    >
                      Go to checkout
                    </Button>
                  </div>
                </>
              ) : (
                <span>Your reports cart is empty</span>
              )}
            </div>
          </Fade>
        )}
      </Popper>
      <div aria-label="cart badge">
        <Badge color="error" overlap="circular" badgeContent={reportItemsCount}>
          <Fab
            color="primary"
            size="small"
            onClick={handlePopperClick("bottom-end")}
            className="report-cart-badge-fab"
          >
            <DescriptionRounded />
          </Fab>
        </Badge>
      </div>
    </div>
  );
};

export default ReportCartBadge;
