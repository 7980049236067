// General
import React from "react";
// Components
import CalendarTodayRounded from "@mui/icons-material/CalendarTodayRounded";
import { useI18n } from "compass-commons";
import DatePicker from "./datePicker/DatePicker";
// Styles
import "react-datepicker/dist/react-datepicker.css";
import "./timePeriods.module.css";
// Helpers
import useWindowDimensions from "../../../helpers/useWindowDimensions";

interface TimePeriodsProps {
  includeTime: boolean;
  isStartDateCurrent?: boolean;
  isEndDateCurrent?: boolean;
  startDate?: Date;
  endDate?: Date;
  onChangeStartDate?: ({ startDateInput: Date }) => void;
  onChangeEndDate?: ({ endDateInput: Date }) => void;
}

const TimePeriods = (props: TimePeriodsProps): JSX.Element => {
  const {
    isStartDateCurrent,
    isEndDateCurrent,
    startDate,
    endDate,
    onChangeStartDate,
    onChangeEndDate,
    includeTime,
  } = props;

  const { width } = useWindowDimensions();
  const { t: translate } = useI18n();

  const handleChangeStartDate = (startDateVal) => {
    onChangeStartDate(startDateVal);
  };

  const handleChangeEndDate = (endDateVal) => {
    onChangeEndDate(endDateVal);
  };

  return (
    <>
      <div className="row">
        <div className="col align-self-start">
          <i className="compass-component-icon">
            <CalendarTodayRounded />
          </i>
        </div>
        <div className="col align-self-end" />
      </div>
      <div className="row">
        <div className={width < 1600 ? "col-5" : "col-4"}>
          <p className="time-label">
            <b>{translate("filters.dateSelection.startDate")}:</b>
          </p>
        </div>
        <div
          style={{ zIndex: 10 }}
          className={width < 1600 ? "col-7" : "col-8"}
        >
          <DatePicker
            name="startDate"
            includeTime={includeTime}
            currentDate={isStartDateCurrent}
            data-cy="startDate"
            initialDate={startDate}
            onChangeCallBack={handleChangeStartDate}
          />
        </div>
      </div>
      <div
        style={{
          paddingBottom: "var(--msi-ui-spacing-xs)",
          paddingTop: "var(--msi-ui-spacing-s)",
        }}
      />
      <div className="row">
        <div className={width < 1600 ? "col-5" : "col-4"}>
          <p className="time-label">
            <b>{translate("filters.dateSelection.endDate")}:</b>
          </p>
        </div>
        <div style={{ zIndex: 5 }} className={width < 1600 ? "col-7" : "col-8"}>
          <DatePicker
            name="endDate"
            includeTime={includeTime}
            data-cy="endDate"
            currentDate={isEndDateCurrent}
            initialDate={endDate}
            onChangeCallBack={handleChangeEndDate}
          />
        </div>
      </div>
    </>
  );
};

TimePeriods.defaultProps = {
  isStartDateCurrent: false,
  isEndDateCurrent: false,
  startDate: null,
  endDate: null,
  onChangeStartDate: null,
  onChangeEndDate: null,
};

export default TimePeriods;
