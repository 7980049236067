import { capitalizeFirstLetter } from "compass-commons";
import { parse } from "date-fns";
import moment from "moment/moment";

const normalizeWord = (word: string): string => {
  let normalized = "";
  let biWordIndex = 0;
  if (word.length === 2) {
    return word;
  }

  // eslint-disable-next-line no-plusplus
  for (let i = 0; i < word.length; i++) {
    if (word[i] === word[i].toUpperCase()) {
      biWordIndex = i;
      break;
    }
  }

  if (biWordIndex === 0) {
    normalized = word[0].toUpperCase() + word.substring(1);
  } else if (word.length > biWordIndex + 1) {
    normalized = `${capitalizeFirstLetter(
      word.substring(0, biWordIndex)
    )} ${capitalizeFirstLetter(word.substring(biWordIndex))}`;
  }

  return normalized;
};

const getInitStartDate = (date: string) => {
  let initStartDate: Date;
  if (date == null) {
    const today = new Date();

    initStartDate = new Date(
      today.getFullYear(),
      today.getMonth(),
      today.getDate(),
      0,
      0,
      0
    );
  } else {
    initStartDate = parse(date, "yyyy-MM-dd'T'HH:mm:ss.SSS'Z'", new Date());
  }

  return initStartDate;
};

const defaultDateFormat = "yyyy/MM/dd";
const isValidDate = (
  date: Date,
  dateFormat: string = defaultDateFormat
): boolean => {
  if (!date) return false;

  return moment(
    date,
    dateFormat.replace(defaultDateFormat, defaultDateFormat.toUpperCase()),
    true
  ).isValid();
};

export { normalizeWord, getInitStartDate, isValidDate };
