import React from "react";
import GroupInfoSearchResult from "../../../../../models/incidents/GroupInfoSearchResult";
import { normalizeWord } from "../../../../../util/Util";
import useWindowDimensions from "../../../../../helpers/useWindowDimensions";

interface SunburstGroupLabelsProps {
  visibleData: GroupInfoSearchResult;
}

const SunburstGroupLabels = (props: SunburstGroupLabelsProps): JSX.Element => {
  const { visibleData } = props;
  const dimensions = useWindowDimensions();

  const getIncidentsCount = () => {
    let count = 0;
    for (let i = 0; i < visibleData?.children?.length; i += 1) {
      count += visibleData.children[i].count;
    }
    if (count === 0) {
      count += visibleData?.count;
    }
    return count;
  };

  const obtainValuesAndCounts = (
    data: GroupInfoSearchResult,
    category: string,
    valuesAndCounts = new Map<string, number>()
  ): Map<string, number> => {
    if (data?.children) {
      for (let i = 0; i < data?.children.length; i += 1) {
        const child = data.children[i];
        if (child.category === category) {
          if (valuesAndCounts.has(child.value)) {
            const count = valuesAndCounts.get(child.value) + child.count;
            valuesAndCounts.set(child.value, count);
          } else {
            valuesAndCounts.set(child.value, child.count);
          }
        }
        obtainValuesAndCounts(child, category, valuesAndCounts);
      }
    }
    return valuesAndCounts;
  };

  const boxStyle = { height: "14px", width: "14px", borderRadius: "25px" };

  const getInnerRing = () => {
    const ring = visibleData?.children?.length >= 1 ? visibleData : null;

    if (ring === null) {
      return <></>;
    }
    return (
      <div className="sunburst-labels-single-ring">
        <span style={{ color: "#6e6e6e" }}>Inner ring</span>
        <span>{normalizeWord(ring.children[0].category)}</span>
        <div className="sunburst-labels-single-ring-blocks">
          {ring?.children?.map((child) => {
            return (
              <div style={{ display: "flex", gap: "6px" }} key={child.id}>
                {child.category === "incidentPriorities" && (
                  <div
                    className="compass-rounded-corner"
                    style={{ ...boxStyle, background: child.color }}
                  />
                )}
                <span>
                  <b>{child.count}</b>&nbsp;{child.value}
                </span>
              </div>
            );
          })}
        </div>
      </div>
    );
  };

  const getMiddleRing = () => {
    let middleRingCategory = null;
    for (let i = 0; i < visibleData?.children?.length; i += 1) {
      for (let j = 0; j < visibleData?.children[i].children?.length; j += 1) {
        if (visibleData?.children[i].children[j]) {
          middleRingCategory = visibleData?.children[i].children[j].category;
          break;
        }
      }
    }

    if (middleRingCategory === null) {
      return <></>;
    }
    const middleRingValuesAndCounts = obtainValuesAndCounts(
      visibleData,
      middleRingCategory
    );

    const labels = [];
    middleRingValuesAndCounts.forEach((value, key) => {
      labels.push(
        <span key={labels.length}>
          <b>{value}</b>&nbsp;{key}
        </span>
      );
    });
    return (
      <div className="sunburst-labels-single-ring">
        <span style={{ color: "#6e6e6e" }}>Middle ring</span>
        <span>{normalizeWord(middleRingCategory)}</span>
        <div className="sunburst-labels-single-ring-blocks">{labels}</div>
      </div>
    );
  };

  const getOuterRing = () => {
    let outerRingCategory = null;
    for (let i = 0; i < visibleData?.children?.length; i += 1) {
      for (let j = 0; j < visibleData?.children[i].children?.length; j += 1) {
        for (
          let k = 0;
          k < visibleData?.children[i].children[j].children?.length;
          k += 1
        ) {
          if (visibleData?.children[i].children[j].children[k]) {
            outerRingCategory =
              visibleData?.children[i].children[j].children[k].category;
            break;
          }
        }
      }
    }

    if (outerRingCategory === null) {
      return <></>;
    }

    const outerRingValuesAndCounts = obtainValuesAndCounts(
      visibleData,
      outerRingCategory
    );

    const labels = [];
    outerRingValuesAndCounts.forEach((value, key) => {
      labels.push(
        <span key={labels.length}>
          <b>{value}</b>&nbsp;{key}
        </span>
      );
    });

    return (
      <div className="sunburst-labels-single-ring">
        <span style={{ color: "#6e6e6e" }}>Outer ring</span>
        <span>{normalizeWord(outerRingCategory)}</span>
        <div className="sunburst-labels-single-ring-blocks">{labels}</div>
      </div>
    );
  };

  return (
    <div className="sunburst-labels-main-div">
      {visibleData?.children && (
        <div className="sunburst-labels-div">
          <span>
            <b>{getIncidentsCount()}</b> incidents
          </span>
          <div className="sunburst-labels-rings">
            {getInnerRing()}
            {getMiddleRing()}
            {getOuterRing()}
          </div>
        </div>
      )}
    </div>
  );
};

export default SunburstGroupLabels;
