// General
import React, { useEffect, useState } from "react";
// Styles
import "./imageGallery.module.css";
// Components
import { ImageGallery as DMSImageGallery, DMSImageGalleryData } from "dms-lib";
// Model
import {
  InfoFieldPhoto,
  MediaFileStatus,
  MediaInfo,
  isMediaAvailable,
  isMediaWaiting,
} from "compass-commons";
import MediaFileManagerService from "../../../services/MediaFileManagerService";
import { InfoFieldPhotoShort } from "../../../models/incidents/media/InfoFieldPhotoShort";
import GetMediaFileError from "../../../errors/GetMediaFileError";
// Helpers
import { buildCaption } from "../../../helpers/captionHelper";

interface ImageGalleryProps {
  isOpen: boolean;
  onClose: () => void;
  mediaData?: InfoFieldPhoto[];
}

type DMSImageGalleryMap = {
  [key: string]: DMSImageGalleryData;
};

const ImageGallery = (props: ImageGalleryProps): JSX.Element => {
  const { isOpen, onClose, mediaData } = props;

  const checkErrorStatus = (mediaFileStatus: MediaFileStatus) =>
    !isMediaAvailable(mediaFileStatus) && !isMediaWaiting(mediaFileStatus);

  const convertToDMSImageGalleryData = (
    src: string,
    hasError: boolean,
    infoFieldPhotoShort: InfoFieldPhotoShort,
    isLoading: boolean
  ): DMSImageGalleryData => ({
    src,
    hasError,
    isLoading,
    caption: buildCaption(infoFieldPhotoShort, "\n"),
  });

  const buildImageMap = (
    imageDataArray: InfoFieldPhoto[]
  ): DMSImageGalleryMap =>
    imageDataArray?.reduce(
      (previousValue: DMSImageGalleryMap, currentValue: InfoFieldPhoto) => ({
        ...previousValue,
        [currentValue.fileId]: convertToDMSImageGalleryData(
          "",
          !!currentValue.mediaFile?.mediaFileStatus &&
            checkErrorStatus(currentValue.mediaFile?.mediaFileStatus),
          {
            fileId: currentValue.fileId,
            mediaTimestamp: currentValue.mediaFile?.mediaTimestamp,
            resourceMappingName: currentValue.resourceMapping?.name,
          },
          true
        ),
      }),
      {}
    );

  const [imagesToDisplayMap, setImagesToDisplayMap] =
    useState<DMSImageGalleryMap>(buildImageMap(mediaData));

  const getMediaFile = async (mediaId) => {
    const media = await MediaFileManagerService.getMediaById(mediaId)
      .then((res: MediaInfo) => {
        return res;
      })
      .catch((error) => {
        console.log(error);
        throw new GetMediaFileError(mediaId);
      });

    return media;
  };

  // eslint-disable-next-line consistent-return
  const loadImage = async (fileId: string = null): Promise<any> => {
    try {
      const mediaId = fileId;
      const content = await MediaFileManagerService.getMediaContentById(
        mediaId
      );
      if (content) {
        const imageBlob = URL.createObjectURL(content);
        return imageBlob;
      }
    } catch (error) {
      console.log(error);
    }
  };

  const fetchDetails = async (
    fileId: string,
    index: number,
    resourceMappingName?: string
  ) => {
    // Generate a unique fileId, for messages that had an error being created
    const generatedFileId = fileId ?? `nullishId-${index}`;

    let mediaDetail: MediaInfo;
    let mediaContent;
    try {
      if (fileId) {
        mediaDetail = await getMediaFile(fileId);

        if (!checkErrorStatus(mediaDetail?.mediaFileStatus)) {
          mediaContent = await loadImage(mediaDetail.id);
        }
      }
    } catch (error) {
      console.log(error);
    } finally {
      // Some cases where we can see the error image
      // 1 - If fileId is null
      // 2 - Correct fileId, but status is UPLOAD_FAILED
      const errorCondition =
        !fileId ||
        (!!mediaDetail?.mediaFileStatus &&
          checkErrorStatus(mediaDetail?.mediaFileStatus));

      setImagesToDisplayMap((previousValue) => ({
        ...previousValue,
        [generatedFileId]: convertToDMSImageGalleryData(
          mediaContent ?? "",
          errorCondition,
          {
            fileId: generatedFileId,
            mediaTimestamp: mediaDetail?.mediaTimestamp,
            resourceMappingName,
          },
          false
        ),
      }));
    }
  };

  useEffect(() => {
    mediaData.forEach((ifp, index) => {
      fetchDetails(ifp.fileId, index, ifp.resourceMapping?.name);
    });
    return () =>
      Object.keys(imagesToDisplayMap).forEach((key) =>
        URL.revokeObjectURL(imagesToDisplayMap[key].src)
      );
  }, []);

  return (
    <DMSImageGallery
      imagesData={imagesToDisplayMap}
      isOpen={isOpen}
      onClose={onClose}
    />
  );
};

ImageGallery.defaultProps = {
  mediaData: null,
};

export default ImageGallery;
