// General
import React from "react";
import { Incident, useI18n } from "compass-commons";
// Components
import { IncidentIcon } from "dms-lib";
import NewReleasesRoundedIcon from "@mui/icons-material/NewReleasesRounded";

interface TimelineHeaderProps {
  baseColor: string;
  incident: Incident;
  content: JSX.Element;
}

const TimelineHeader = (props: TimelineHeaderProps): JSX.Element => {
  const { baseColor, incident, content } = props;
  const { t: translate } = useI18n();
  return (
    <div className="timeline-item" data-cr="timeline-header">
      <div className="timeline-separator">
        <IncidentIcon
          src={incident.icon}
          priority={incident.priority}
          size="extra-large"
          dataCr="timeline-header-icon"
          badgeIcon={
            incident.triggerType === "ON-DEMAND" && <NewReleasesRoundedIcon />
          }
          badgeProps={{
            tooltip: {
              title: translate("incidentTriggerType.onDemand"),
            },
          }}
        />
        <div
          className="timeline-connector"
          style={{
            backgroundColor: baseColor,
          }}
        />
      </div>
      <div className="timeline-content">{content}</div>
    </div>
  );
};

export default TimelineHeader;
