import { AxiosResponse } from "axios";
import { ErrorResponseDto } from "../models/ErrorResponseDto";
import IncidentListTooLargeError from "./IncidentListTooLargeError";

export function backendErrorDispatcher(error: AxiosResponse): void {
  const backendError = error?.data as ErrorResponseDto;

  switch (error?.status) {
    case 416: {
      if (backendError?.errorCode === 601) {
        const requestedCount = Number(backendError?.errorDetails[0]);
        throw new IncidentListTooLargeError(
          backendError.errorMessage,
          requestedCount
        );
      }
      break;
    }
    default: {
      throw Error(backendError?.errorMessage);
    }
  }
}
