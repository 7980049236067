import { BehaviorSubject } from "rxjs";
import {
  FilterCriteria,
  Incident,
  ReportCart,
  ResultViewEnum,
} from "compass-commons";
import { IAlertNotification } from "dms-lib";
import FilterCriteriaBuilder from "../models/filters/FilterCriteriaBuilder";
import { IncidentSearchResultDTO } from "../models/filters/IncidentSearchResultDTO";
import GroupInfoSearchResult from "../models/incidents/GroupInfoSearchResult";
import { StateHolder } from "../models/state/StateHolder";
import IncidentListTooLargeError from "../errors/IncidentListTooLargeError";

interface LoadingState {
  isLoading: boolean;
}

class StateService {
  public selectedIncidentId: BehaviorSubject<string>;

  public selectedIncident: BehaviorSubject<Incident>;

  public selectedResultsView: BehaviorSubject<ResultViewEnum>;

  public currentIncidentSearchResult: BehaviorSubject<IncidentSearchResultDTO>;

  public currentGroupInfoSearchResult: BehaviorSubject<GroupInfoSearchResult>;

  public selectedGroupInfoSearchResult: BehaviorSubject<GroupInfoSearchResult>;

  public detailsPanelHidden: BehaviorSubject<boolean>;

  public filterPanelHidden: BehaviorSubject<boolean>;

  public resultsPanelHidden: BehaviorSubject<boolean>;

  public incidentListLimitReached: BehaviorSubject<IncidentListTooLargeError>;

  public searchLoading: BehaviorSubject<LoadingState>;

  public mediaLoading: BehaviorSubject<LoadingState>;

  public filterCriteriaHolder: BehaviorSubject<FilterCriteria>;

  public sourceHolder: BehaviorSubject<Map<string, Array<string>>>;

  public firstResponseDisabled: BehaviorSubject<boolean>;

  public timeOfResponseDisabled: BehaviorSubject<boolean>;

  public currentReportCart: BehaviorSubject<ReportCart>;

  public sunburstQuery: BehaviorSubject<FilterCriteria>;

  public badgeAlreadyHasSelectedItemMessage: BehaviorSubject<string>;

  public stateHolder: BehaviorSubject<StateHolder>;

  public alertSubject: BehaviorSubject<IAlertNotification>;

  initializeStates(): void {
    this.selectedIncidentId = new BehaviorSubject<string>(null);
    this.selectedIncident = new BehaviorSubject<Incident>(null);
    this.selectedResultsView = new BehaviorSubject<ResultViewEnum>(
      ResultViewEnum.GRAPHIC
    );
    this.currentIncidentSearchResult =
      new BehaviorSubject<IncidentSearchResultDTO>(null);
    this.currentGroupInfoSearchResult =
      new BehaviorSubject<GroupInfoSearchResult>(null);
    this.selectedGroupInfoSearchResult =
      new BehaviorSubject<GroupInfoSearchResult>(null);
    this.detailsPanelHidden = new BehaviorSubject<boolean>(true);
    this.filterPanelHidden = new BehaviorSubject<boolean>(false);
    this.resultsPanelHidden = new BehaviorSubject<boolean>(true);
    this.incidentListLimitReached =
      new BehaviorSubject<IncidentListTooLargeError>(undefined);
    this.searchLoading = new BehaviorSubject<LoadingState>(null);
    this.mediaLoading = new BehaviorSubject<LoadingState>(null);
    this.filterCriteriaHolder = new BehaviorSubject<FilterCriteria>(
      new FilterCriteriaBuilder().build()
    );
    this.sourceHolder = new BehaviorSubject<Map<string, Array<string>>>(
      new Map<string, Array<string>>()
    );
    this.firstResponseDisabled = new BehaviorSubject<boolean>(true);
    this.timeOfResponseDisabled = new BehaviorSubject<boolean>(true);
    this.sunburstQuery = new BehaviorSubject<FilterCriteria>(null);
    this.currentReportCart = new BehaviorSubject<ReportCart>(null);
    this.badgeAlreadyHasSelectedItemMessage = new BehaviorSubject<string>(null);
    this.stateHolder = new BehaviorSubject<StateHolder>(null);
    this.alertSubject = new BehaviorSubject<IAlertNotification>(null);
  }

  constructor() {
    this.initializeStates();
  }

  clearStates(): void {
    this.initializeStates();
  }
}

export default StateService;
