import React, { useEffect, useState } from "react";
import { SizeMe } from "react-sizeme";
import {
  VictoryScatter,
  VictoryChart,
  VictoryAxis,
  VictoryZoomContainer,
} from "victory";
import "./resultsChart.module.css";
import {
  getBaseColor,
  getStringFromDate,
  IncidentPoint,
  incidentPriority,
} from "compass-commons";
import createDateFromString from "../../../../helpers/createDateFromString";
import Datapoint from "./Datapoint";
import StateService from "../../../../services/StateService";
import { useStateContext } from "../../../../contexts/StateContext";

interface ResultsChartProps {
  incidentPointsList?: IncidentPoint[];
  isOrderByPriority?: boolean;
}

const ResultsChart = (props: ResultsChartProps): JSX.Element => {
  const stateService: StateService = useStateContext();
  const incidentIdSubject = stateService.selectedIncidentId;
  const { incidentPointsList, isOrderByPriority } = props;
  const [zoomDomain, setZoomDomain] = useState(null);
  const [currentId, setCurrentId] = useState(incidentIdSubject.value);
  const priorityOrder = [
    incidentPriority.CRITICAL,
    incidentPriority.WARNING,
    incidentPriority.MAJOR,
    incidentPriority.MINOR,
  ];

  useEffect(() => {
    const subscription = incidentIdSubject.subscribe(setCurrentId);

    return function cleanup() {
      subscription.unsubscribe();
    };
  }, [incidentIdSubject]);

  const handleZoomDomainChange = (newZoomDomain) => {
    setZoomDomain(newZoomDomain);
  };

  /**
   * Verifies if a new incidentPoint will collide on the same column
   * because it has a colliding time range
   * @param datapoint
   * @param columnDataPoints
   */
  function isDataPointCollision(
    datapoint: IncidentPoint,
    columnDataPoints: IncidentPoint[]
  ) {
    if (columnDataPoints != null && columnDataPoints.length > 0) {
      const dataPointStartTime = createDateFromString(
        datapoint.creationTimestamp
      );
      const dataPointEndTime = createDateFromString(datapoint.clearTimestamp);

      for (let i = 0; i < columnDataPoints.length; i += 1) {
        const point = columnDataPoints[i];
        const pointStartTime = createDateFromString(point.creationTimestamp);
        const pointEndTime = createDateFromString(point.clearTimestamp);

        // Check if datapoint is colliding with another point in the same column
        // TODO WHAT IF POINT STARTS OR ENDS OUT OF THE SCOPE
        if (
          (dataPointStartTime >= pointStartTime &&
            dataPointStartTime <= pointEndTime) ||
          (dataPointEndTime >= pointStartTime &&
            dataPointEndTime <= pointEndTime)
        ) {
          return true;
        }
      }
    }

    return false;
  }

  /**
   * Evaluates what the first column to assess will be based on the point category and all
   * of the points that were already attributed to the column.
   * If no columns contain that category, starts on the last+1 X
   * @param incidentPoint
   * @param currentMap
   */
  function getStartingColumn(
    incidentPoint: IncidentPoint,
    currentMap: Map<number, IncidentPoint[]>
  ) {
    let startingColumn;
    let lastColumn = 0;

    Array.from(currentMap.entries()).forEach((entry) => {
      const columnNumber: number = entry[0];
      const columnPoints: IncidentPoint[] = entry[1];

      // The starting column will be the first column that has the same category
      if (
        startingColumn == null &&
        columnPoints != null &&
        columnPoints.length > 0 &&
        columnPoints[0].priority === incidentPoint.priority
      ) {
        startingColumn = columnNumber;
        // TODO BREAK OUT OF LOOP
      }

      if (columnNumber > lastColumn) {
        lastColumn = columnNumber;
      }
    });

    // If no column was found, then it should create a new column
    if (startingColumn == null) {
      startingColumn = lastColumn + 1;
    }

    return startingColumn;
  }

  /**
   * Calculates the X position (or the column number) based on previous calculated points and on the
   * category of the datapoint
   * @param incidentPoint
   * @param currentMap
   */
  function calculateXPosition(
    incidentPoint: IncidentPoint,
    currentMap: Map<number, IncidentPoint[]>
  ) {
    let added = false;
    let i = isOrderByPriority
      ? getStartingColumn(incidentPoint, currentMap)
      : 1;

    const addToArray = (x) => {
      if (currentMap.has(x)) {
        currentMap.get(x).push(incidentPoint);
      } else {
        currentMap.set(x, [incidentPoint]);
      }
    };

    while (!added) {
      if (isDataPointCollision(incidentPoint, currentMap.get(i))) {
        i += 1;
      } else {
        addToArray(i);
        added = true;
      }
    }

    return i;
  }

  /**
   * Sorts all of the dataPoints by priority
   * @param dataPoints
   * @param sortingArr
   */
  function sortPointsByPriority(
    dataPoints: IncidentPoint[],
    sortingArr: string[]
  ) {
    return dataPoints.slice().sort((a, b) => {
      if (a.priority != null && b.priority != null) {
        return (
          sortingArr.indexOf(a.priority.toLowerCase()) -
          sortingArr.indexOf(b.priority.toLowerCase())
        );
      }
      return 1;
    });
  }

  function getScatterData() {
    const dataPoints = isOrderByPriority
      ? sortPointsByPriority(incidentPointsList, priorityOrder)
      : incidentPointsList;
    const axisXPointDistribution: Map<number, IncidentPoint[]> = new Map();

    const data = [];
    for (let i = 0; i < dataPoints.length; i += 1) {
      const datapoint = dataPoints[i];
      const timestamp: Date =
        datapoint.creationTimestamp != null
          ? createDateFromString(datapoint.creationTimestamp)
          : new Date();
      if (timestamp != null) {
        const x = calculateXPosition(datapoint, axisXPointDistribution);
        const y = timestamp;
        data.push({
          x,
          y,
          size: 6,
          fill: getBaseColor(datapoint.priority),
          id: datapoint.id,
          selected: datapoint.id === currentId,
        });
      }
    }

    return data;
  }

  const handlePointClick = (id: string): void => {
    if (id != null && id !== "") {
      incidentIdSubject.next(id);
      // TODO could also make the call here to get the incident and add it to state
    }
  };

  return (
    <SizeMe monitorHeight>
      {({ size }) => (
        <div className="results-chart-div compass-fade-in-smooth">
          <VictoryChart
            padding={{ left: 80 }}
            domainPadding={50}
            style={{
              background: { fill: "white" },
            }}
            scale={{ y: "time" }}
            height={size.height}
            width={size.width}
            containerComponent={
              <VictoryZoomContainer
                zoomDomain={zoomDomain}
                onZoomDomainChange={handleZoomDomainChange}
                minimumZoom={{ y: 900000 }}
                responsive={false}
              />
            }
          >
            <VictoryAxis
              dependentAxis
              orientation="left"
              tickFormat={(tick) => {
                const x = new Date(tick);
                return getStringFromDate(x, true);
              }}
              style={{
                axis: { stroke: "transparent" },
                tickLabels: { fontSize: 12 },
                grid: { stroke: "#818e99", strokeWidth: 0.5 },
              }}
            />
            <VictoryScatter
              data={getScatterData()}
              dataComponent={<Datapoint onClick={handlePointClick} />}
              style={{
                data: {
                  fill: ({ datum }) => datum.fill,
                  opacity: ({ datum }) => datum.opacity,
                },
              }}
            />
          </VictoryChart>
        </div>
      )}
    </SizeMe>
  );
};

ResultsChart.defaultProps = {
  incidentPointsList: [],
  isOrderByPriority: true,
};

export default ResultsChart;
