// Generaç
import { AllowedModules, CompassAuth } from "compass-shared-services";

const USER_MANAGER_URL = appConfig.USER_MANAGER_SERVICE_PATH;

const URL_PATHS = {
  GET_USER_USERGROUPS: `${USER_MANAGER_URL}/manager/user-groups/logged-user`,
};

const compassAuth = CompassAuth(appConfig.SERVER_URL, appConfig.NAMESPACE);

class UserManagerService {
  static async isUserAllowed(): Promise<boolean> {
    try {
      return await compassAuth.isUserAllowed(
        URL_PATHS.GET_USER_USERGROUPS,
        AllowedModules.DATAEXPLORER
      );
    } catch (error) {
      return false;
    }
  }
}

export default UserManagerService;
