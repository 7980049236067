import React, {
  ChangeEvent,
  useCallback,
  useEffect,
  useRef,
  useState,
} from "react";
import Input from "@msi/cobalt-react/input";
import "./slider.module.css";

interface SliderProps {
  disabled: boolean;
  min: number;
  max: number;
  onChange: ({ min, max }) => void;
  selectedMin?: number;
  selectedMax?: number;
}

const Slider = (props: SliderProps): JSX.Element => {
  const { min, max, disabled, onChange, selectedMin, selectedMax } = props;
  const [minVal, setMinVal] = useState(selectedMin != null ? selectedMin : min);
  const [maxVal, setMaxVal] = useState(selectedMax != null ? selectedMax : max);
  const minValRef = useRef(selectedMin != null ? selectedMin : min);
  const maxValRef = useRef(selectedMax != null ? selectedMax : max);
  const range = useRef<HTMLDivElement>(null);

  // Convert to percentage
  const getPercent = useCallback(
    (value: number) => Math.round(((value - min) / (max - min)) * 100),
    [min, max]
  );

  // Set width of the range to decrease from the left side
  useEffect(() => {
    const minPercent = getPercent(minVal);
    const maxPercent = getPercent(maxValRef.current);

    if (range.current) {
      range.current.style.left = `${minPercent}%`;
      range.current.style.width = `${maxPercent - minPercent}%`;
    }
  }, [minVal, getPercent]);

  // Set width of the range to decrease from the right side
  useEffect(() => {
    const minPercent = getPercent(minValRef.current);
    const maxPercent = getPercent(maxVal);

    if (range.current) {
      range.current.style.width = `${maxPercent - minPercent}%`;
    }
  }, [maxVal, getPercent]);

  // Get min and max values when their state changes
  useEffect(() => {
    onChange({ min: minVal, max: maxVal });
  }, [minVal, maxVal, onChange]);

  const rightPHStyleEnabled = {
    marginRight: "2%",
    opacity: 1,
  };

  const rightPHStyle = {
    marginRight: "2%",
  };

  const leftPHStyleEnabled = {
    marginLeft: "2%",
    opacity: 1,
  };

  const leftPHStyle = {
    marginLeft: "2%",
  };

  return (
    <div style={{ display: "flex" }} data-cr="de-slider">
      <Input
        style={disabled ? rightPHStyle : rightPHStyleEnabled}
        className="slider-input"
        disabled
        type="text"
        placeholder={minVal}
      />
      <div className="container">
        <input
          type="range"
          min={min}
          max={max}
          value={minVal}
          onChange={(event: ChangeEvent<HTMLInputElement>) => {
            const value = Math.min(Number(event.target.value), maxVal - 1);
            setMinVal(value);
            minValRef.current = value;
          }}
          className="thumb thumb--left"
          style={{ zIndex: minVal > max - 100 && 5 }}
          disabled={disabled}
        />
        <input
          type="range"
          min={min}
          max={max}
          value={maxVal}
          onChange={(event: ChangeEvent<HTMLInputElement>) => {
            const value = Math.max(Number(event.target.value), minVal + 1);
            setMaxVal(value);
            maxValRef.current = value;
          }}
          className="thumb thumb--right"
          disabled={disabled}
        />
        <div className="slider">
          <div className="slider__track" />
          <div
            style={disabled ? { opacity: 0.4 } : {}}
            ref={range}
            className="slider__range"
          />
        </div>
      </div>
      <Input
        style={disabled ? leftPHStyle : leftPHStyleEnabled}
        className="slider-input"
        disabled
        type="text"
        placeholder={maxVal}
      />
    </div>
  );
};

Slider.defaultProps = {
  selectedMin: null,
  selectedMax: null,
};

export default Slider;
