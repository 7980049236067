/**
 * Creates date object from string being passed in.
 * @param value @type string
 * @returns Date
 */
const createDateFromString = (value: string): Date => {
  if (!value || typeof value !== "string") return undefined;

  const date = new Date(value);
  // eslint-disable-next-line no-restricted-globals
  if (isNaN(date.getTime())) return undefined;
  return date;
};

export default createDateFromString;
