import { FilterCriteria, getNewId } from "compass-commons";

export default class GroupInfoSearchResult {
  category: string;

  value: string;

  color: string;

  count: number;

  size: number;

  query: FilterCriteria;

  children: GroupInfoSearchResult[];

  id: string;

  parentId: string;

  constructor(jsonObject?: GroupInfoSearchResult) {
    this.id = getNewId();

    if (jsonObject) {
      this.value = jsonObject.value;
      this.count = jsonObject.count;
      this.color = jsonObject.color;
      this.size = jsonObject.size;
      this.category = jsonObject.category;
      this.query = jsonObject.query as FilterCriteria;
      this.parentId = jsonObject.parentId;

      if (jsonObject.id) {
        this.id = jsonObject.id;
      }

      if (jsonObject.children && jsonObject.children?.length > 0) {
        this.children = [];

        jsonObject.children.forEach((child) => {
          if ("count" in child && child.count > 0) {
            const childObj = new GroupInfoSearchResult(child);
            childObj.parentId = this.id;
            this.children.push(childObj);
          }
        });
      } else {
        this.children = [];
        this.size = jsonObject.size || 1;
      }
    }
  }

  static copy(object: GroupInfoSearchResult): GroupInfoSearchResult {
    const newObj = new GroupInfoSearchResult();
    if (object) {
      newObj.id = object.id;
      newObj.parentId = object.parentId;
      newObj.value = object.value;
      newObj.count = object.count;
      newObj.color = object.color;
      newObj.size = object.size;
      newObj.category = object.category;
      newObj.query = object.query;
      newObj.children = [];

      object.children.forEach((child) => {
        newObj.children.push(GroupInfoSearchResult.copy(child));
      });
    }
    return newObj;
  }
}
