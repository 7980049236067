// General
import React, { useCallback, useEffect, useRef, useState } from "react";
// Styles
import "./activationsTable.module.css";
// Components
import { Skeleton } from "@mui/material";
import { Incident, safelyFormatDate, useI18n } from "compass-commons";
// Hooks
import useActivations from "../../../hooks/useActivations";
import { IncidentActivationsGroupDTO } from "../../../models/incidents/ActivationsGroup";

const PAGE_SIZE = 20;
const SCROLL_THRESHOLD = 100;

const ActivationsTable = ({
  incident,
  stepId,
}: {
  incident: Incident;
  stepId: string;
}): JSX.Element => {
  const { t: translate } = useI18n();
  const listRef = useRef<HTMLDivElement>(null);
  const [pageIndex, setPageIndex] = useState(0);
  const [listData, setListData] = useState<IncidentActivationsGroupDTO[]>(null);
  const [isAllDataFetched, setIsAllDataFetched] = useState(false);

  const { loading, error, data } = useActivations({
    incidentId: incident.id,
    pageIndex,
    pageSize: PAGE_SIZE,
    stepId,
  });

  // Append & Format paginated data
  useEffect(() => {
    if (!data) return;
    if (data.length < PAGE_SIZE) setIsAllDataFetched(true);

    const initialDate = incident.creationTimestamp?.split("T")[0];
    const dataFormatted: IncidentActivationsGroupDTO[] = data.map(
      (activation) => {
        const activationDate = activation.createdTimestamp?.split("T")[0];
        return {
          createdTimestamp:
            initialDate === activationDate
              ? safelyFormatDate(
                  activation.createdTimestamp,
                  undefined,
                  "HH:mm:ss"
                )
              : safelyFormatDate(activation.createdTimestamp),
          resourceName: activation.resourceName,
        };
      }
    );
    setListData((prev) =>
      prev === null ? dataFormatted : [...prev, ...dataFormatted]
    );
  }, [data]);

  const handleScroll = useCallback(() => {
    // Stop page updates in case of reaching the last page
    if (isAllDataFetched || error) return;

    const currentPos = listRef.current?.scrollTop;
    const { clientHeight, scrollHeight } = listRef.current || {};

    const x = currentPos + clientHeight;

    if (scrollHeight - x <= SCROLL_THRESHOLD && !loading && !error) {
      setPageIndex((prev) => prev + 1);
    }
  }, [pageIndex, isAllDataFetched, loading, error]);

  return (
    <div
      className="activations-table"
      data-cr="activations-table"
      onScroll={handleScroll}
      ref={listRef}
    >
      {listData &&
        listData.map((activation) => (
          <div
            className="activations-table-row activations-table-item"
            key={`${activation.resourceName}-${activation.createdTimestamp}`}
          >
            <span>{activation.resourceName}</span>
            <span className="activation-table-row-date">
              {activation.createdTimestamp}
            </span>
          </div>
        ))}
      {loading && (
        <div className="activations-table-row">
          <Skeleton
            variant="rectangular"
            className="activations-table-skeleton"
          />
        </div>
      )}
      {(error || listData?.length === 0) && (
        <div className="activations-table-row activations-table-error">
          <span>{translate("resources.activationsTableError")}</span>
        </div>
      )}
    </div>
  );
};

export default ActivationsTable;
