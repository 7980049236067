/**
 * ROUTER DATA_EXPLORER HELPERS
 */
// The root path for this configuration microFrontend
/* eslint-disable import/prefer-default-export */

function getMiddlePath(url: string, first: string, second: string) {
  const regex = new RegExp(`/${first}(.*?)${second}`);
  return regex.exec(url)?.[1] || "/";
}

const url = !isStandalone ? window.location.href : "/report/dataexplorer";
const middlePath = getMiddlePath(url, "report", "dataexplorer");
export const ROOTPATH = `/report${middlePath}dataexplorer`;

export const REPORT_CHECKOUT_PATH = `/report${middlePath}dataexplorer/report-checkout`;
