/**
 * STRING CONSTANTS FILE
 */
import SvgIcon from "@mui/material/SvgIcon";
import AttachmentRounded from "@mui/icons-material/AttachmentRounded";
import CallRounded from "@mui/icons-material/CallRounded";
import CameraAltRounded from "@mui/icons-material/CameraAltRounded";
import CheckRounded from "@mui/icons-material/CheckRounded";
import CircleRounded from "@mui/icons-material/CircleRounded";
import MarkEmailUnreadRounded from "@mui/icons-material/MarkEmailUnreadRounded";
import MessageRounded from "@mui/icons-material/MessageRounded";
import OndemandVideoRounded from "@mui/icons-material/OndemandVideoRounded";
import SquareRounded from "@mui/icons-material/SquareRounded";
import VolumeUpRounded from "@mui/icons-material/VolumeUpRounded";
import RepeatRoundedIcon from "@mui/icons-material/RepeatRounded";
import { BadgeRoundedIcon } from "dms-lib";

export const defaultFilterLevels = [
  "incidentPriorities",
  "incidentTypes",
  "sites",
];

export const incidentStepIconNamesMap: Record<string, typeof SvgIcon> = {
  ic_camera: CameraAltRounded,
  circle: CircleRounded,
  square: SquareRounded,
  ic_video: OndemandVideoRounded,
  ic_audio_speaker: VolumeUpRounded,
  ic_mail_unread: MarkEmailUnreadRounded,
  ic_call: CallRounded,
  ic_attachment: AttachmentRounded,
  ic_conversation: MessageRounded,
  ic_check: CheckRounded,
  ic_id_card: BadgeRoundedIcon,
  ic_repeat: RepeatRoundedIcon,
};

export const SHOW_GENERIC_ERROR_MESSAGES_FEATURE_FLAG =
  "b_showGenericErrorMessages";
