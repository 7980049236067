import React from "react";

interface DatapointProps {
  x?: number;
  y?: number;
  datum?: any;
  onClick?: (id: string) => void;
}

const Datapoint = (props: DatapointProps): JSX.Element => {
  const { x, y, datum, onClick } = props;
  const [hovered, setHovered] = React.useState(false);

  const getId = (): string => {
    return datum ? datum.id : null;
  };

  return (
    <circle
      data-cy="results-chart-datapoint"
      className={`compass-clickable ${
        datum.selected ? "datapoint-selected" : ""
      }`}
      cx={x}
      cy={y}
      r={8}
      stroke={hovered || datum.selected ? datum.fill : "none"}
      strokeWidth={datum.selected ? 10 : 5}
      fill={datum.fill}
      onClick={() => onClick(getId())}
      onMouseEnter={() => setHovered(true)}
      onMouseLeave={() => setHovered(false)}
    />
  );
};

export default Datapoint;
