// General
import React, {
  MutableRefObject,
  useCallback,
  useEffect,
  useState,
} from "react";
import {
  IncidentFields,
  PagedFilterCriteria,
  ResultViewEnum,
  useI18n,
} from "compass-commons";
import { format, parse } from "date-fns";
// Components
import { IncidentIcon } from "dms-lib";
import NewReleasesRoundedIcon from "@mui/icons-material/NewReleasesRounded";
import TableView from "../../../commons/tableView/TableView";
import NoResults from "../noResults/NoResults";
// Styles
import "./resultTable.module.css";
// Store
import { useStateContext } from "../../../../contexts/StateContext";
// Services
import StateService from "../../../../services/StateService";
import DataExplorerService from "../../../../services/DataExplorerService";
// Models
import { IncidentSearchPagedResultDTO } from "../../../../models/filters/IncidentSearchPagedResultDTO";

interface ResultTableProps {
  value?: string;
  resultPanelRef?: MutableRefObject<any>;
}

const ResultTable = (props: ResultTableProps): JSX.Element => {
  const { value, resultPanelRef } = props;
  const { t: translate } = useI18n();
  const stateService: StateService = useStateContext();
  const { filterCriteriaHolder, selectedIncidentId, sunburstQuery } =
    stateService;
  const [incidentList, setIncidentList] = useState([]);
  const [loading, setLoading] = useState(true);
  const [pageCount, setPageCount] = useState(0);
  const fetchIdRef = React.useRef(0);
  const [errorMessage, setErrorMessage] = useState("");
  const [totalRecords, setTotalRecords] = useState(0);
  const [componentQuery, setComponentQuery] = useState(sunburstQuery.value);
  const [currentId, setCurrentId] = useState(selectedIncidentId.value);

  useEffect(() => {
    setComponentQuery(sunburstQuery.value);
    const subscription = sunburstQuery.subscribe((val) => {
      if (val != null && val !== componentQuery) {
        setComponentQuery(val);
      }
    });

    const selectedIncidentSub = selectedIncidentId.subscribe(setCurrentId);

    return function cleanup() {
      subscription.unsubscribe();
      selectedIncidentSub.unsubscribe();
    };
  }, [stateService, sunburstQuery, selectedIncidentId]);

  /**
   * Get criteria for Table Query
   * @param pageIndex
   * @param pageSize
   * @param orderBy
   * @param orderDirection
   */
  const getCriteria = (
    pageIndex: number,
    pageSize: number,
    orderBy?: string,
    orderDirection?: string
  ) => {
    const filterCriteria =
      componentQuery != null ? componentQuery : filterCriteriaHolder.getValue();
    const criteria: PagedFilterCriteria = {
      startDate: filterCriteria.startDate,
      endDate: filterCriteria.endDate,
      sites: filterCriteria.sites,
      incidentPriorities: filterCriteria.incidentPriorities,
      includeComment: filterCriteria.includeComment,
      includeFiles: filterCriteria.includeFiles,
      includeOnDemand: filterCriteria.includeOnDemand,
      incidentTypes: filterCriteria.incidentTypes,
      sources: filterCriteria.sources,
      operators: filterCriteria.operators,
      firstResponseTime: filterCriteria.firstResponseTime,
      timeOfResponse: filterCriteria.timeOfResponse,
      resultView: ResultViewEnum.TABLE,
      pageIndex,
      customerId: "qwerty", // TODO remove
      pageSize,
      orderBy,
      orderDirection,
    };

    return criteria;
  };

  /**
   * Search incidents by pageIndex
   * @param pageIndex
   * @param pageSize
   * @param orderBy
   * @param orderDirection
   */
  const searchIncidents = async (
    pageIndex: number,
    pageSize: number,
    orderBy?: string,
    orderDirection?: string
  ): Promise<IncidentSearchPagedResultDTO> => {
    let searchResultDTO: IncidentSearchPagedResultDTO = null;
    try {
      const pagedCriteria: PagedFilterCriteria = getCriteria(
        pageIndex,
        pageSize,
        orderBy,
        orderDirection
      );
      searchResultDTO = await DataExplorerService.findPagedIncidentsByCriteria(
        pagedCriteria
      );
    } catch (error) {
      setErrorMessage(error);
    }
    return searchResultDTO;
  };

  const TABLE_HEADER = {
    Incident: "Incident",
    Description: "Incident Description",
    Site: "Site",
    Triggered: "Triggered",
    Activations: "Activations",
    Cleared: "Cleared",
    Operator: "Operator(s)",
    Summary: "Summary",
    Source: "Source",
  };

  const convertTableHeaderToIncidentField = (header?): IncidentFields => {
    if (header) {
      switch (header) {
        case TABLE_HEADER.Incident:
          return IncidentFields.ID;
        case TABLE_HEADER.Description:
          return IncidentFields.DESCRIPTION;
        case TABLE_HEADER.Site:
          return IncidentFields.SITE_NAME;
        case TABLE_HEADER.Triggered:
          return IncidentFields.TRIGGER_TIME;
        case TABLE_HEADER.Activations:
          return IncidentFields.ACTIVATIONS_COUNT;
        case TABLE_HEADER.Cleared:
          return IncidentFields.CLEAR_TIME;
        case TABLE_HEADER.Operator:
          return IncidentFields.OPERATOR_NAME;
        case TABLE_HEADER.Summary:
          return IncidentFields.SUMMARY;
        case TABLE_HEADER.Source:
          return IncidentFields.RESOURCE_MAPPING_NAME;
        default:
          return null;
      }
    }
    return null;
  };

  /**
   * Fetch Data callback is used as callback method for table.
   */
  const fetchData = useCallback(
    ({ pageSize, pageIndex }, orderBy?, orderDirection?) => {
      // Give this fetch an ID
      // eslint-disable-next-line no-plusplus
      const fetchId = ++fetchIdRef.current;

      // Set the loading state
      setLoading(true);

      const orderByNorm = convertTableHeaderToIncidentField(orderBy);

      if (fetchId === fetchIdRef.current) {
        searchIncidents(pageIndex, pageSize, orderByNorm, orderDirection)
          .then((response) => {
            setIncidentList(response.incidentList);
            setPageCount(response.pageCount);
            setTotalRecords(response.totalRecords);

            setLoading(false);
          })
          .catch(() => setLoading(false));
      }
    },
    []
  );

  /**
   * This component is used for Summary column
   * @param fsProps
   * @constructor
   */
  const FormattedSummary = (fsProps) => {
    const { row } = fsProps;
    const divs = [];
    if (row.summary) {
      const summary = row.summary.toString();
      const summaryLines =
        summary.indexOf("\\n") > -1
          ? summary.split("\\n")
          : summary.split(". ");
      for (let i = 0; i < summaryLines.length; i += 1) {
        divs.push(<div key={i}>{summaryLines[i]}</div>);
      }
    }

    return (
      <>
        <div key={row.id} style={{ display: "inline-table" }}>
          {divs}
        </div>
      </>
    );
  };

  const safelyFormatDate = (dateString: string) => {
    const date = new Date(dateString);

    return date ? format(date, "LLL d HH:mm:ss") : null;
  };

  /**
   * Large Media
   * This is used for table columns and
   * its configuration and style.
   */
  const largeMediaTable = React.useMemo(
    () => [
      {
        id: "table-view",
        Header: "Test",
        hideHeader: true,
        columns: [
          {
            Header: "ID",
            accessor: "id",
          },
          {
            Header: TABLE_HEADER.Incident,
            accessor: (row) => row.id.substring(0, 7), // TODO remove this substring when BE sends code
            minWidth: 100,
          },
          {
            Header: TABLE_HEADER.Description,
            accessor: (row) => (
              <div style={{ display: "flex" }}>
                <IncidentIcon
                  src={row.icon}
                  priority={row.priority}
                  size="medium"
                  badgeIcon={
                    row.triggerType === "ON-DEMAND" && (
                      <NewReleasesRoundedIcon />
                    )
                  }
                  badgeProps={{
                    tooltip: {
                      title: translate("incidentTriggerType.onDemand"),
                    },
                  }}
                />
                <div style={{ margin: "10px", width: "125px" }}>
                  {row.description}
                </div>
              </div>
            ),
            minWidth: 170,
          },
          {
            Header: TABLE_HEADER.Site,
            accessor: "location",
            minWidth: 120,
          },
          {
            Header: TABLE_HEADER.Triggered,
            accessor: (row) => {
              return safelyFormatDate(row.creationTimestamp);
            },
            minWidth: 120,
          },
          {
            Header: TABLE_HEADER.Activations,
            accessor: (row) => row.activationsCount + 1,
            minWidth: 50,
          },
          {
            Header: TABLE_HEADER.Cleared,
            accessor: (row) => {
              return safelyFormatDate(row.clearTimestamp);
            },
            minWidth: 120,
          },
          {
            Header: TABLE_HEADER.Operator,
            accessor: (row) => {
              return row.user
                ? `${row.user.firstName} 
                ${row.user.lastName ? row.user.lastName : ""}`
                : "-";
            },
            minWidth: 140,
          },
          {
            Header: TABLE_HEADER.Summary,
            accessor: (row) => {
              return <FormattedSummary row={row} />;
            },
            minWidth: 200,
          },
          {
            Header: TABLE_HEADER.Source,
            accessor: "sourceName",
            minWidth: 170,
          },
        ],
      },
    ],
    []
  );

  /**
   * SmallView is for media type less than 1024px
   * This is used for table columns and
   * its configuration and style.
   */
  const SmallMedia = (smtProps) => {
    const { data } = smtProps;

    return (
      <>
        <div className="row">
          <div className="col-4">
            <img
              src={data.icon}
              alt="icon"
              style={{ width: "35px", height: "35px", float: "right" }}
            />
          </div>
          <div className="col-8">
            <div>
              <p
                className="small-table"
                style={{ fontSize: "medium", fontWeight: "bold" }}
              >
                {data.description}
              </p>
            </div>
            <div style={{ marginBottom: "10px" }}>
              <p className="small-table">{data.location}</p>
            </div>
          </div>
          <div className="col-4">
            <p
              className="small-table"
              style={{ float: "right", fontWeight: "bold" }}
            >
              {TABLE_HEADER.Triggered}
            </p>
          </div>
          <div className="col-8">
            {format(
              parse(
                data.creationTimestamp,
                "yyyy-MM-dd'T'HH:mm:ss.SSSxxx",
                new Date()
              ),
              "LLL d HH:m:s"
            )}
          </div>

          <div className="col-4">
            <p
              className="small-table"
              style={{ float: "right", fontWeight: "bold" }}
            >
              {TABLE_HEADER.Cleared}
            </p>
          </div>
          <div className="col-8">
            {format(
              parse(
                data.clearTimestamp,
                "yyyy-MM-dd'T'HH:mm:ss.SSS",
                new Date()
              ),
              "LLL d HH:m:s"
            )}
          </div>

          <div className="col-4">
            <p
              className="small-table"
              style={{ float: "right", fontWeight: "bold" }}
            >
              {TABLE_HEADER.Operator}
            </p>
          </div>
          <div className="col-8">
            {`${data.user?.firstName} ${data.user?.lastName}`}
          </div>

          <div className="col-4">
            <p
              className="small-table"
              style={{ float: "right", fontWeight: "bold" }}
            >
              {TABLE_HEADER.Summary}{" "}
            </p>
          </div>
          <div className="col-8">
            <FormattedSummary row={data} />
          </div>

          <div className="col-4">
            <p style={{ float: "right", fontWeight: "bold" }}>
              {TABLE_HEADER.Source}{" "}
            </p>
          </div>
          <div className="col-8">
            <p>{data.sourceName}</p>
          </div>
        </div>
      </>
    );
  };
  const smallMediaTable = React.useMemo(
    () => [
      {
        id: "table-view",
        Header: "Test",
        hideHeader: true,
        columns: [
          {
            Header: "ID",
            accessor: "id",
          },
          {
            Header: "Incidents",
            accessor: (row) => {
              return <SmallMedia data={row} />;
            }, // TODO remove this substring when BE sends code
            maxWidth: 1023,
            minWidth: "fit-content",
            width: "fit-content",
          },
        ],
      },
    ],
    []
  );

  /**
   * This callback method is used for table on row click.
   */
  const handlePointClick = useCallback((row: any): void => {
    if (row != null && row.values !== "") {
      selectedIncidentId.next(row.values.id);
      // TODO could also make the call here to get the incident and add it to state
    }
  }, []);

  const getColumns = () => {
    return largeMediaTable;
  };

  /**
   * This array is used to hide columns. We need to use ID for detail.
   */
  const hiddenColumns = ["id"];

  return (
    <>
      <TableView
        onRowClickCallback={handlePointClick}
        data={incidentList}
        columns={getColumns()}
        totalPageCount={pageCount}
        apiCallFunc={fetchData}
        totalDataCount={totalRecords}
        loading={loading}
        hideColumns={hiddenColumns}
        sortable
        excludeColumnsFromSorting={[]}
        selectedRowId={currentId}
        resultPanelRef={resultPanelRef}
      />
      {!incidentList?.length && !loading && <NoResults />}
    </>
  );
};

export default ResultTable;
