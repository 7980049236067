import React, { useEffect, useState } from "react";
import Spinner from "@msi/cobalt-react/spinner";
import ReactPlayer from "react-player";
import "./mediaModal.module.css";
import StateService from "../../../services/StateService";
import { useStateContext } from "../../../contexts/StateContext";

interface MediaModalProps {
  show: boolean;
  id: string;
  mediaTitle?: string;
  mediaInfo?: string;
  mediaType: string;
  onClose: (Event) => void;
  activeModalId: string;
  mediaFile: any;
}

const VIDEO = ["webm", "mp4"];
const AUDIO = ["wav", "mp3"];
const IMAGE = ["jpeg", "jpg"];

const MediaModal = (props: MediaModalProps): JSX.Element => {
  const {
    onClose,
    show,
    id,
    activeModalId,
    mediaFile,
    mediaType,
    mediaInfo,
    mediaTitle,
  } = props;
  const [isPlaying, setIsPlaying] = useState(false);
  const stateService: StateService = useStateContext();
  const mediaLoadingSubject = stateService.mediaLoading;
  const [loading, setLoading] = useState(mediaLoadingSubject.value?.isLoading);
  const [muted, setMuted] = useState(false);

  useEffect(() => {
    setLoading(mediaLoadingSubject.value?.isLoading);

    const mediaLoadingSubsription = mediaLoadingSubject.subscribe((val) => {
      if (val != null) {
        setLoading(val?.isLoading);
      }
    });

    return function cleanup() {
      mediaLoadingSubsription.unsubscribe();
    };
  }, [stateService, mediaLoadingSubject]);

  const handleOnClose = (e) => {
    mediaLoadingSubject.next({ isLoading: false });
    onClose(e);
  };

  const handleIsPlaying = (e) => {
    setIsPlaying(false);
  };

  const handleMute = (e) => {
    setMuted(!muted);
  };

  const ImageMedia = () => {
    let imageUrl = null;
    if (mediaFile != null) {
      const urlCreator = window.URL || window.webkitURL;
      imageUrl = urlCreator.createObjectURL(mediaFile);
    }
    const imageStyle = {
      width: "100%",
      height: "100%",
    };
    return imageUrl != null ? (
      <img style={imageStyle} src={imageUrl} alt="snapshot" />
    ) : (
      <></>
    );
  };

  const VideoMedia = () => {
    let videoUrl = null;
    if (mediaFile != null) {
      const urlCreator = window.URL || window.webkitURL;
      videoUrl = urlCreator.createObjectURL(mediaFile);
    }
    return videoUrl != null ? (
      <ReactPlayer
        className="react-player"
        url={videoUrl}
        width="100%"
        height="100%"
        playing={isPlaying}
        controls
      />
    ) : (
      <></>
    );
  };

  const AudioMedia = () => {
    let audioUrl = null;
    if (mediaFile != null) {
      const urlCreator = window.URL || window.webkitURL;
      audioUrl = urlCreator.createObjectURL(mediaFile);
    }
    return audioUrl != null ? (
      <ReactPlayer
        className="react-player"
        url={audioUrl}
        width="100%"
        height="100%"
        playing={isPlaying}
        controls
      />
    ) : (
      <></>
    );
  };

  const MediaContent = (mediaProps): JSX.Element => {
    const { media } = mediaProps;
    if (VIDEO.indexOf(media) >= 0) {
      return <VideoMedia />;
    }

    if (IMAGE.indexOf(media) >= 0) {
      return <ImageMedia />;
    }

    if (AUDIO.indexOf(media) >= 0) {
      return <AudioMedia />;
    }

    return <></>;
  };

  const ModalHeader = () => {
    const infoDivStyle = {
      marginTop: "10px",
      display: "grid",
      marginLeft: "5px",
    };
    return (
      <div className="modal-header-wrapper">
        <div style={infoDivStyle}>
          <span>{mediaTitle}</span>
          <span>{mediaInfo}</span>
        </div>
        <div
          onClick={handleOnClose}
          onKeyDown={() => {
            // console.log();
          }}
          role="button"
          tabIndex={0}
        >
          <span className="close" />
        </div>
      </div>
    );
  };

  return id !== activeModalId || !show ? (
    <></>
  ) : (
    <div className="media-modal" id={`modal-${id}`} data-cr="de-media-modal">
      <ModalHeader />
      <div className="content">
        {loading ? (
          <div
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              margin: "20%",
            }}
          >
            <Spinner size="large" />
          </div>
        ) : (
          <div className="player-wrapper">
            <MediaContent media={mediaType} />
          </div>
        )}
      </div>
    </div>
  );
};

MediaModal.defaultProps = {
  mediaTitle: "Media",
  mediaInfo: "",
};

export default MediaModal;
