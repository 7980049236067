import React, { useEffect, useState } from "react";
import { Checkbox } from "dms-lib";
import "./firstResponse.module.css";

import Slider from "../../../commons/slider/Slider";
import ResponseTimeInterval from "../../../../models/filters/ResponseTimeInterval";

interface FirstResponseProps {
  disable?: boolean;
  minValue: number;
  maxValue: number;
  selected: ResponseTimeInterval;
  disableCallback: (event) => void;
  onChangeCallback: (event) => void;
}

const FirstResponse = (props: FirstResponseProps): JSX.Element => {
  const {
    disable,
    onChangeCallback,
    minValue,
    maxValue,
    selected,
    disableCallback,
  } = props;
  const [isSelected, setIsSelected] = useState(!disable);
  const [firstResponseMin, setFirstResponseMin] = useState(null);
  const [firstResponseMax, setFirstResponseMax] = useState(null);

  useEffect(() => {
    onChangeCallback({ min: firstResponseMin, max: firstResponseMax });
  }, [firstResponseMin, firstResponseMax]);

  const onChange = (e) => {
    setIsSelected(e);
    disableCallback(e);
    if (!e) {
      setFirstResponseMin(minValue);
      setFirstResponseMax(maxValue);
    }
  };

  return (
    <>
      <div className="row" data-cr="de-first-response">
        <div style={{ display: "flex" }} className="col-12 time-response-align">
          <Checkbox
            label="First Response"
            key="firstResponse"
            checked={isSelected}
            onChange={(_, checked) => onChange(checked)}
          />
          <p
            style={{
              fontSize: "small",
              paddingTop: "5%",
            }}
          >
            (in minutes)
          </p>
        </div>
      </div>
      <div className="row">
        <div style={{ display: "flex", marginLeft: "17%" }} className="col-12">
          <Slider
            disabled={!isSelected}
            selectedMin={selected?.min}
            selectedMax={selected?.max}
            min={minValue}
            max={maxValue}
            onChange={({ min, max }: { min: number; max: number }) => {
              setFirstResponseMin(min);
              setFirstResponseMax(max);
            }}
          />
        </div>
        <div className="col" />
      </div>
    </>
  );
};

FirstResponse.defaultProps = { disable: false };
export default FirstResponse;
