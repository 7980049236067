import React from "react";
import { useI18n } from "compass-commons";
import "./noResults.module.css";

const NoResults = (): JSX.Element => {
  const { t: translate } = useI18n();
  return (
    <div data-cy="results-empty" className="results-view-no-incidents">
      <span className="no-results-title">
        {translate("results.resultsNotFound")}
      </span>
      <span className="no-results-action">
        {translate("results.tryNewFilter")}
      </span>
    </div>
  );
};

export default NoResults;
