import React, { RefObject, useEffect, useState } from "react";
import { BehaviorSubject } from "rxjs";
import { normalizeWord } from "../../../../../util/Util";

interface SunburstGroupInfoTooltipProps {
  nodeSubject: BehaviorSubject<any>;
  containerRef: RefObject<HTMLDivElement>;
}

const SunburstGroupTooltipInfo = (
  props: SunburstGroupInfoTooltipProps
): JSX.Element => {
  const { nodeSubject, containerRef } = props;
  const [mousePosition, setMousePosition] = useState(null);
  const [hoveredNode, setHoveredNode] = useState(null);
  const [containerRect, setContainerRect] = useState<DOMRect>(null);
  let getRectsInterval;

  useEffect(() => {
    window.addEventListener("mousemove", setMousePosition, false);
    nodeSubject.subscribe((value) => {
      setHoveredNode(value);
    });

    getRectsInterval = setInterval(() => {
      setContainerRect(containerRef.current?.getBoundingClientRect());
    }, 10);

    return function cleanup() {
      nodeSubject.unsubscribe();
      clearInterval(getRectsInterval);
    };
  }, [nodeSubject]);

  function getTooltipStyle() {
    /* eslint-disable no-restricted-globals */
    const y = mousePosition.clientY;
    const x = mousePosition.clientX;
    const left = x - containerRect?.left + 40;
    const top = y - containerRect?.top + 70;
    return {
      top,
      left,
    };
  }

  const boxStyle = { height: "14px", width: "14px", borderRadius: "25px" };

  return (
    <>
      {hoveredNode?.value && mousePosition && (
        <div className="sunburst-tooltip-div" style={getTooltipStyle()}>
          <div className="sunburst-tooltip">
            <div className="sunburst-tooltip-left">
              <div
                className="compass-rounded-corner"
                style={{ ...boxStyle, background: hoveredNode.color }}
              />
            </div>
            <div className="sunburst-tooltip-right">
              <span>
                {normalizeWord(hoveredNode.category)}:&nbsp;
                <i>{hoveredNode.value}</i>:&nbsp;
                <b>{hoveredNode.count}</b>
              </span>
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default SunburstGroupTooltipInfo;
