import { CSSProperties } from "react";

const systemDashedConnector = (baseColor: string): CSSProperties => {
  return {
    backgroundColor: baseColor,
    color: baseColor,
    borderColor: baseColor,
  };
};

const firstOperatorConnector = (baseColor: string): CSSProperties => {
  return {
    width: "35px",
    borderColor: baseColor,
    color: baseColor,
    backgroundColor: baseColor,
    borderTopLeftRadius: "25px",
    borderTopRightRadius: "25px",
    marginTop: "-12px",
  };
};

const defaultOperatorConnector = (baseColor: string): CSSProperties => {
  return {
    width: "35px",
    borderColor: baseColor,
    color: baseColor,
    backgroundColor: baseColor,
  };
};

const lastOperatorConnector = (baseColor: string): CSSProperties => {
  return {
    width: "35px",
    borderColor: baseColor,
    color: baseColor,
    backgroundColor: baseColor,
    borderBottomLeftRadius: "25px",
    borderBottomRightRadius: "25px",
    minHeight: "none",
  };
};

const firstAndLastOperatorConnector = (baseColor: string): CSSProperties => {
  return {
    width: "35px",
    borderColor: baseColor,
    color: baseColor,
    backgroundColor: baseColor,
    borderBottomLeftRadius: "25px",
    borderBottomRightRadius: "25px",
    borderTopLeftRadius: "25px",
    borderTopRightRadius: "25px",
    minHeight: "none",
    marginTop: "-12px",
  };
};

const lastItem = (): CSSProperties => {
  return {
    minHeight: "0",
  };
};

export {
  systemDashedConnector,
  firstOperatorConnector,
  defaultOperatorConnector,
  lastOperatorConnector,
  lastItem,
  firstAndLastOperatorConnector,
};
