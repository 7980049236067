import React, { useEffect, useRef, useState } from "react";
import "./datePicker.module.css";
import ReactDatePicker from "react-datepicker";
import { format, parse } from "date-fns";
import { Button } from "dms-lib";
import createDateFromString from "../../../../helpers/createDateFromString";
import TimePickerSlider from "../timePickerSlider/TimePickerSlider";
import { isValidDate } from "../../../../util/Util";

interface DatePickerProps {
  name: string;
  includeTime?: boolean;
  tabIndex?: number;
  currentDate?: boolean;
  dateFormat?: string;
  initialDate?: Date;
  onChangeCallBack?: (selectedDate: Date) => void;
}

const DatePicker = (props: DatePickerProps): JSX.Element => {
  const {
    includeTime,
    tabIndex,
    currentDate,
    dateFormat,
    name,
    initialDate,
    onChangeCallBack,
  } = props;
  const finalDateFormat = includeTime ? `${dateFormat} HH:mm` : dateFormat;

  const getInitialDate = () => {
    if (currentDate && !initialDate) {
      return createDateFromString(format(new Date(), finalDateFormat));
    }

    if (isValidDate(initialDate, finalDateFormat)) {
      return createDateFromString(format(initialDate, finalDateFormat));
    }
    return null;
  };

  const [selectedDate, setSelectedDate] = useState(getInitialDate());

  const [datePickerIsOpen, setDatePickerIsOpen] = useState(false);
  const [counter, setCounter] = useState(0);
  const [activeElement, setActiveElement] = useState(null);
  const datePickerRef = React.createRef();
  const isManualInputRef = useRef(false);

  const onChange = (val) => {
    if (!isManualInputRef.current && isValidDate(val, finalDateFormat)) {
      setSelectedDate(val);
      onChangeCallBack(val);
    }
    isManualInputRef.current = false;
  };

  const handleChangeRaw = (value) => {
    isManualInputRef.current = !!value;

    if (isValidDate(value, finalDateFormat)) {
      const newDate = new Date(value);
      setSelectedDate(newDate);
      onChangeCallBack(newDate);
    } else {
      onChangeCallBack(null); // not a valid date
      console.error("Invalid date, null or not valid format", finalDateFormat);
    }
  };

  const validateActiveComponent = () => {
    const activeComponent = document.querySelector(
      "[class*=-active-component]"
    );
    if (!activeComponent) {
      setActiveElement(
        `${props.name.trim().replace(" ", "")}-active-component`
      );
    } else {
      activeComponent.dispatchEvent(
        new MouseEvent("click", {
          view: window,
          bubbles: true,
          cancelable: true,
          buttons: 1,
        })
      );
    }
  };

  const showDatePicker = (isOpen: boolean): void => {
    if (isOpen) {
      setDatePickerIsOpen(true);
      setCounter(counter + 1);
      validateActiveComponent();
    } else {
      setDatePickerIsOpen(false);
      setCounter(0);
      setActiveElement(null);
    }
  };

  const isClickOnSlickSlider = (event): boolean => {
    if (
      event.nativeEvent &&
      event.nativeEvent.relatedTarget &&
      event.nativeEvent.relatedTarget.className.indexOf("slick") >= 0
    ) {
      return true;
    }

    if (
      !event.target.className &&
      event.target.parentElement &&
      event.target.parentElement.parentElement &&
      event.target.parentElement.parentElement.className.indexOf("slick") >= 0
    ) {
      return true;
    }

    return (
      event.target.className && event.target.className.indexOf("slick") >= 0
    );
  };

  const setDateInputValue = (value) => {
    const timerElement = document.querySelector(
      `input[name='${props.name}']`
    ) as HTMLInputElement;
    timerElement.value = format(value, finalDateFormat);
  };

  const openDatePicker = (event) => {
    event.stopPropagation();
    if (event.nativeEvent.type === "click") {
      setDateInputValue(selectedDate);
    }
    if (
      event.target.className &&
      event.target.className.indexOf("react-datepicker") >= 0
    ) {
      return;
    }

    if (isClickOnSlickSlider(event)) return;

    if (counter % 2 === 0) {
      showDatePicker(true);
    } else {
      showDatePicker(false);
    }
  };

  const closeDatePickerIfOpen = (event) => {
    event.stopPropagation();
    if (
      event.nativeEvent.relatedTarget &&
      event.nativeEvent.relatedTarget.className &&
      event.nativeEvent.relatedTarget.className.indexOf("react-datepicker") >= 0
    ) {
      return;
    }

    if (
      event.target.className &&
      event.target.className.indexOf("date-picker") >= 0 &&
      counter > 0
    ) {
      return;
    }

    if (isClickOnSlickSlider(event)) return;
    showDatePicker(false);
  };

  const handleCallback = (sliderData) => {
    const timerElement = document.querySelector(`input[name='${props.name}']`);
    const dateTime = timerElement.getAttribute("value");
    const date = dateTime.split(" ")[0];
    const newDate = parse(`${date} ${sliderData}`, finalDateFormat, new Date());
    setSelectedDate(newDate);
    onChangeCallBack(newDate);
    timerElement.setAttribute("value", format(newDate, finalDateFormat));
  };

  useEffect(() => {
    if (datePickerIsOpen) {
      const element = document.getElementsByClassName("react-datepicker__day");
      Array.from(element).forEach((el) => {
        el.classList.remove("react-datepicker__day--keyboard-selected");
      });
    }
  }, [datePickerIsOpen]);

  return (
    <>
      <div
        className={
          activeElement != null
            ? `${"date-picker "}${activeElement}`
            : "date-picker"
        }
        style={{
          display: "flex",
        }}
        tabIndex={tabIndex}
        onClick={openDatePicker}
        onKeyDown={openDatePicker}
        onBlur={closeDatePickerIfOpen}
        role="button"
        data-cy={name}
        data-cr={name}
      >
        <ReactDatePicker
          name={name}
          selected={selectedDate}
          onChange={onChange}
          dateFormat={finalDateFormat}
          showTimeInput
          onChangeRaw={(event) => handleChangeRaw(event.target.value)}
          open={datePickerIsOpen}
          placeholderText={finalDateFormat}
          popperPlacement="bottom-end"
          formatWeekDay={(nameOfDay) => nameOfDay.substr(0, 1)}
          ref={datePickerRef}
        >
          {includeTime && (
            <TimePickerSlider
              initialHour={selectedDate.getHours()}
              initialMinute={selectedDate.getMinutes()}
              datePickerCallBack={handleCallback}
            />
          )}
          <div
            className="react-datepicker-confirmation"
            style={{
              display: "flex",
              flexDirection: "row",
              justifyContent: "center",
            }}
          >
            <Button
              color="primary"
              variant="text"
              className="react-datepicker-button"
              onClick={() => {
                showDatePicker(false);
              }}
            >
              Done
            </Button>
          </div>
        </ReactDatePicker>

        <div className="date-picker-icon" role="button" tabIndex={tabIndex}>
          {!datePickerIsOpen ? (
            <span className="icon-arrow-down" />
          ) : (
            <span className="icon-arrow-up" />
          )}
        </div>
      </div>
    </>
  );
};

DatePicker.defaultProps = {
  includeTime: false,
  tabIndex: 0,
  currentDate: false,
  dateFormat: "yyyy/MM/dd",
  onChangeCallBack: null,
  initialDate: null,
};

export default DatePicker;
