import {
  FilterCriteria,
  Incident,
  MediaRequest,
  getUrl,
  normalizeAPIObject,
} from "compass-commons";
import { HttpClient } from "compass-shared-services";
import { GroupListDTO } from "../models/filters/GroupListDTO";
import { IncidentPriority } from "../models/filters/IncidentPriority";
import { IncidentTypeDTO } from "../models/filters/IncidentTypeDTO";
import { SourceListDTO } from "../models/filters/SourceListDTO";
import { mapGroupInfoFromAPI } from "../helpers/mapGroupInfoData";
import GroupInfoSearchResult from "../models/incidents/GroupInfoSearchResult";
import { IncidentSearchResultDTO } from "../models/filters/IncidentSearchResultDTO";
import { IncidentSearchPagedResultDTO } from "../models/filters/IncidentSearchPagedResultDTO";
import { IncidentPriorityListDTO } from "../models/filters/IncidentPriorityListDTO";
import { SourceListResultDTO } from "../models/filters/SourceListResultDTO";
import { OperatorListResultDTO } from "../models/filters/OperatorListResultDTO";
import { SiteTreeNodeDTO } from "../models/filters/SiteTreeNodeDTO";
import { backendErrorDispatcher } from "../errors/backendErrorDispatcher";
import {
  IncidentActivationsGroupDTO,
  IncidentActivationsGroupDTOResponse,
} from "../models/incidents/ActivationsGroup";

const {
  INCIDENT_RULES_ENGINE_PATH,
  FILE_CONNECTOR_PATH,
  SITE_MANAGER_PATH,
  INCIDENT_MANAGER_PATH,
} = appConfig;
const httpClient = new HttpClient(appConfig);
const URL_PATHS = {
  INCIDENT_TYPES: `incident-types`,
  OPERATORS: "groups",
  SOURCES: "sources",
  PRIORITIES: "priorities",
  SITES: `${SITE_MANAGER_PATH}/site-configuration/site-tree`,
  INCIDENT_DETAIL: `${INCIDENT_MANAGER_PATH}/incidents/detail`,
  SEARCH_INCIDENTS: `${INCIDENT_MANAGER_PATH}/incidents/list`,
  GET_GROUPED_INFO: `${INCIDENT_MANAGER_PATH}/incidents/graph`,
  GET_MEDIA: `${FILE_CONNECTOR_PATH}/media/getMediaFile`,
  SEARCH_INCIDENTS_BY_PAGINATED: `${INCIDENT_MANAGER_PATH}/incidents/table`,
  GET_ACTIVATIONS_GROUP_PAGINATED: `${INCIDENT_MANAGER_PATH}/incidents/(incidentId)/steps/(stepId)`,
};

export default class DataExplorerService {
  static async getIncidentById(id: string): Promise<Incident> {
    const urlPath = `${URL_PATHS.INCIDENT_DETAIL}/${id}`;
    return httpClient
      .get<Incident>({ url: urlPath })
      .then((response) => {
        return normalizeAPIObject(response);
      })
      .catch(async (error) => {
        throw Error(error);
      });
  }

  static async getSites(): Promise<SiteTreeNodeDTO> {
    const urlPath = `${URL_PATHS.SITES}`;
    return httpClient
      .get<SiteTreeNodeDTO>({ url: urlPath })
      .then((response) => {
        return normalizeAPIObject(response);
      })
      .catch(async (error) => {
        throw Error(error);
      });
  }

  static async getIncidentPriorities(): Promise<IncidentPriority[]> {
    const urlPath = `${INCIDENT_RULES_ENGINE_PATH}/${URL_PATHS.PRIORITIES}`;
    return httpClient
      .get<IncidentPriorityListDTO>({ url: urlPath })
      .then((response) => {
        return response?.priorityDTOList;
      })
      .catch(async (error) => {
        throw Error(error);
      });
  }

  static async getIncidentTypes(): Promise<IncidentTypeDTO[]> {
    const urlPath = `${INCIDENT_RULES_ENGINE_PATH}/${URL_PATHS.INCIDENT_TYPES}`;
    return httpClient
      .get<IncidentTypeDTO[]>({ url: urlPath })
      .then((response) => {
        return response;
      })
      .catch(async (error) => {
        throw Error(error);
      });
  }

  static async getSources(): Promise<SourceListDTO[]> {
    const urlPath = `${INCIDENT_RULES_ENGINE_PATH}/${URL_PATHS.SOURCES}`;
    return httpClient
      .get<SourceListResultDTO>({ url: urlPath })
      .then((response) => {
        return response?.sourceDTOS;
      })
      .catch(async (error) => {
        throw Error(error);
      });
  }

  static async getOperators(): Promise<GroupListDTO[]> {
    const urlPath = `${INCIDENT_RULES_ENGINE_PATH}/${URL_PATHS.OPERATORS}`;
    return httpClient
      .get<OperatorListResultDTO>({ url: urlPath })
      .then((response) => {
        return response?.groupDTOList;
      })
      .catch(async (error) => {
        throw Error(error);
      });
  }

  static async findIncidentsByCriteria(
    criteria: FilterCriteria
  ): Promise<IncidentSearchResultDTO> {
    let incidentSearchResult: IncidentSearchResultDTO;
    const normalizedCriteria = normalizeAPIObject(criteria, { toLocal: false });
    return httpClient
      .post<IncidentSearchResultDTO>({
        url: URL_PATHS.SEARCH_INCIDENTS,
        payload: normalizedCriteria,
      })
      .then((response) => {
        if (response) {
          incidentSearchResult = normalizeAPIObject(response);
        }
        return incidentSearchResult;
      })
      .catch((error) => {
        backendErrorDispatcher(error);
        return undefined;
      });
  }

  static async getGroupedInformationByCriteria(
    criteria: FilterCriteria
  ): Promise<GroupInfoSearchResult> {
    return httpClient
      .post({
        url: URL_PATHS.GET_GROUPED_INFO,
        payload: criteria,
      })
      .then((response) => {
        return mapGroupInfoFromAPI(response);
      })
      .catch(async (error) => {
        throw Error(error);
      });
  }

  static async getMedia(input: MediaRequest): Promise<any> {
    let mediaResult: any;
    return httpClient
      .post<any>({
        url: URL_PATHS.GET_MEDIA,
        payload: input,
        config: {
          responseType: "blob",
        },
      })
      .then((response) => {
        if (response) {
          mediaResult = response;
        }
        return mediaResult;
      })
      .catch(async (error) => {
        throw Error(error);
      });
  }

  static async findPagedIncidentsByCriteria(
    criteria: FilterCriteria
  ): Promise<IncidentSearchPagedResultDTO> {
    let incidentSearchResult: IncidentSearchPagedResultDTO;
    const normalizedCriteria = normalizeAPIObject(criteria, { toLocal: false });
    return httpClient
      .post<IncidentSearchPagedResultDTO>({
        url: URL_PATHS.SEARCH_INCIDENTS_BY_PAGINATED,
        payload: normalizedCriteria,
      })
      .then((response) => {
        if (response) {
          incidentSearchResult = normalizeAPIObject(response);
        }
        return incidentSearchResult;
      })
      .catch(async (error) => {
        throw Error(error);
      });
  }

  static async getActivationsGroupList(
    incidentId: string,
    stepId: string,
    pageParameters?: {
      pageIndex: number;
      pageSize: number;
    }
  ): Promise<IncidentActivationsGroupDTO[]> {
    return httpClient
      .get<IncidentActivationsGroupDTOResponse>({
        url: getUrl(URL_PATHS.GET_ACTIVATIONS_GROUP_PAGINATED, {
          incidentId,
          stepId,
        }),
        config: {
          params: {
            pageIndex: pageParameters?.pageIndex,
            pageSize: pageParameters?.pageSize,
          },
        },
      })
      .then((response) => {
        return normalizeAPIObject(response.activations);
      })
      .catch((error) => {
        throw Error(error);
      });
  }
}
