import { format } from "date-fns";
import {
  FilterCriteria,
  ResponseTimeInterval,
  ResultViewEnum,
} from "compass-commons";

class FilterCriteriaBuilder {
  private readonly filterCriteria: FilterCriteria;

  private readonly DATE_FORMAT: string = "yyyy-MM-dd'T'HH:mm:ss.SSS'Z'";

  constructor() {
    this.filterCriteria = {
      startDate: null,
      endDate: null,
      sites: [],
      incidentPriorities: null,
      includeComment: null,
      includeFiles: null,
      includeOnDemand: null,
      incidentTypes: [],
      sources: [],
      operators: [],
      firstResponseTime: null,
      timeOfResponse: null,
      resultView: ResultViewEnum.GRAPHIC,
    };
  }

  startDate(startDate: Date): FilterCriteriaBuilder {
    if (startDate != null) {
      this.filterCriteria.startDate = format(startDate, this.DATE_FORMAT);
    }

    return this;
  }

  endDate(endDate: Date): FilterCriteriaBuilder {
    if (endDate != null) {
      this.filterCriteria.endDate = format(endDate, this.DATE_FORMAT);
    }

    return this;
  }

  sites(sites: Array<string>): FilterCriteriaBuilder {
    if (sites != null && sites.length > 0) {
      this.filterCriteria.sites = sites;
    }

    return this;
  }

  incidentPriority(incidentPriority: Array<string>): FilterCriteriaBuilder {
    if (incidentPriority != null && incidentPriority.length > 0) {
      this.filterCriteria.incidentPriorities = incidentPriority;
    }
    return this;
  }

  includeComment(includeComment: boolean): FilterCriteriaBuilder {
    if (includeComment != null && includeComment) {
      this.filterCriteria.includeComment = includeComment;
    }
    return this;
  }

  includeFiles(includeFiles: boolean): FilterCriteriaBuilder {
    if (includeFiles != null && includeFiles) {
      this.filterCriteria.includeFiles = includeFiles;
    }

    return this;
  }

  includeOnDemand(includeOnDemand: boolean): FilterCriteriaBuilder {
    if (includeOnDemand) {
      this.filterCriteria.includeOnDemand = true;
    }
    return this;
  }

  incidentTypes(incidentTypes: Array<string>): FilterCriteriaBuilder {
    if (incidentTypes != null && incidentTypes.length > 0) {
      this.filterCriteria.incidentTypes = incidentTypes;
    }

    return this;
  }

  source(source: Array<string>): FilterCriteriaBuilder {
    if (source != null && source.length > 0) {
      this.filterCriteria.sources = source;
    }

    return this;
  }

  operator(operator: Array<string>): FilterCriteriaBuilder {
    if (operator != null && operator.length > 0) {
      this.filterCriteria.operators = operator;
    }

    return this;
  }

  firstResponse(firstResponse: ResponseTimeInterval): FilterCriteriaBuilder {
    if (firstResponse != null) {
      this.filterCriteria.firstResponseTime = {
        min: firstResponse.min,
        max: firstResponse.max,
      };
    }

    return this;
  }

  timeOfResponse(timeOfResponse: ResponseTimeInterval): FilterCriteriaBuilder {
    if (timeOfResponse != null) {
      this.filterCriteria.timeOfResponse = {
        min: timeOfResponse.min,
        max: timeOfResponse.max,
      };
    }

    return this;
  }

  resultView(resultView: ResultViewEnum): FilterCriteriaBuilder {
    if (resultView != null) {
      this.filterCriteria.resultView = resultView;
    }

    return this;
  }

  build(): FilterCriteria {
    return this.filterCriteria;
  }
}

export default FilterCriteriaBuilder;
