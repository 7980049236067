import { ReportCartItemTypeEnum, capitalizeFirstLetter } from "compass-commons";

export default class ReportCartItemAlreadyExistsError extends Error {
  private readonly ERROR_MESSAGE = "Already Exists!";

  constructor(itemType: ReportCartItemTypeEnum) {
    super();
    this.message = `${capitalizeFirstLetter(itemType)} ${this.ERROR_MESSAGE}`;
  }
}
