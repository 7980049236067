import { format, parse } from "date-fns";
import {
  ReportCartItemTypeEnum,
  FilterCriteria,
  ReportCart,
  ReportCartItem,
  ReportCartStateEnum,
  ReportCartList,
  copyObject,
} from "compass-commons";
import { HttpClient } from "compass-shared-services";
import ReportCartItemAlreadyExistsError from "../errors/ReportCartItemAlreadyExistsError";

const { REPORT_CART_MANAGER_PATH } = appConfig;
const httpClient = new HttpClient(appConfig);
const URL_PATHS = {
  CARTS: `${REPORT_CART_MANAGER_PATH}/manager/carts`,
};

export default class ReportCartService {
  private static getComparableObj(criteria: FilterCriteria): FilterCriteria {
    const obj = copyObject(criteria);

    // Set dates without seconds.
    obj.startDate = JSON.stringify(
      format(
        parse(criteria.startDate, "yyyy-MM-dd'T'HH:mm:ss.SSSxxx", new Date()),
        "yyyy-MM-dd'T'HH:mm"
      )
    );
    obj.endDate = JSON.stringify(
      format(
        parse(criteria.endDate, "yyyy-MM-dd'T'HH:mm:ss.SSSxxx", new Date()),
        "yyyy-MM-dd'T'HH:mm"
      )
    );

    return obj;
  }

  private static compareCriteria(
    fcOrigin: FilterCriteria,
    fcTarget: FilterCriteria
  ): boolean {
    const originObj = this.getComparableObj(fcOrigin);
    const targetObj = this.getComparableObj(fcTarget);

    return JSON.stringify(originObj) === JSON.stringify(targetObj);
  }

  private static validateReportCartItem(
    item: ReportCartItem,
    reportCart: ReportCart
  ) {
    if (item.type === ReportCartItemTypeEnum.DETAIL) {
      if (
        reportCart.elementList.filter(
          (o) =>
            o.type === ReportCartItemTypeEnum.DETAIL &&
            o.detailCriteria.incidentId === item.detailCriteria.incidentId
        ).length > 0
      ) {
        throw new ReportCartItemAlreadyExistsError(item.type);
      }
    }

    if (item.type === ReportCartItemTypeEnum.CRITERIA) {
      if (
        reportCart.elementList.filter(
          (o) =>
            o.type === ReportCartItemTypeEnum.CRITERIA &&
            this.compareCriteria(o.filteringCriteria, item.filteringCriteria)
        ).length > 0
      ) {
        throw new ReportCartItemAlreadyExistsError(item.type);
      }
    }
  }

  static async addCartItem(
    reportCart: ReportCart,
    reportCartItem: ReportCartItem
  ): Promise<ReportCart> {
    try {
      let reportCartCurrent: ReportCart = reportCart;
      const item: ReportCartItem = reportCartItem;
      if (reportCartCurrent) {
        if (reportCartCurrent && reportCartCurrent.elementList?.length > 0) {
          this.validateReportCartItem(item, reportCartCurrent);
        }

        const carts = await this.getCarts(ReportCartStateEnum.ACTIVE);
        if (carts) {
          // eslint-disable-next-line prefer-destructuring
          reportCartCurrent = carts[0]; // TODO there is only one active in the cart list. But this may change in the future.
        }
      }

      item.position =
        reportCartCurrent && reportCartCurrent?.getElementCount()
          ? reportCartCurrent?.getElementCount()
          : 0;

      if (reportCartCurrent && reportCartCurrent.id) {
        reportCartCurrent.elementList.push(item);
        return await this.updateCart(reportCartCurrent);
      }
      const newReportCart = new ReportCart();
      newReportCart.elementList = new Array<ReportCartItem>();
      newReportCart.elementList.push(item);
      newReportCart.status = ReportCartStateEnum.ACTIVE;
      reportCartCurrent = await this.createCart(newReportCart);
      return reportCartCurrent;
    } catch (error) {
      if (error instanceof ReportCartItemAlreadyExistsError) {
        throw error;
      }
      throw Error(error);
    }
  }

  static async createCart(reportCard: ReportCart): Promise<ReportCart> {
    return httpClient
      .post<ReportCart>({ url: URL_PATHS.CARTS, payload: reportCard })
      .then((response) => {
        if (response) {
          return new ReportCart(response);
        }
        return new ReportCart();
      })
      .catch(async (error) => {
        throw Error(error);
      });
  }

  static async getCartById(id: string): Promise<ReportCart> {
    const urlPath = `${URL_PATHS.CARTS}/${id}`;
    return httpClient
      .get<ReportCart>({ url: urlPath })
      .then((response) => {
        return new ReportCart(response);
      })
      .catch(async (error) => {
        throw Error(error);
      });
  }

  static async getCarts(state: ReportCartStateEnum): Promise<ReportCart[]> {
    let urlPath = `${URL_PATHS.CARTS}`;
    if (state) {
      urlPath = `${urlPath}?state=${state}`;
    }
    return httpClient
      .get<ReportCartList>({ url: urlPath })
      .then((response) => {
        const cards: ReportCart[] = new Array<ReportCart>();
        if (response?.cartList)
          response?.cartList.forEach((o) => {
            cards.push(new ReportCart(o));
          });
        return cards;
      })
      .catch(async () => {
        throw Error("Failed to get cart");
      });
  }

  static async deleteCartById(id: string): Promise<ReportCart> {
    const urlPath = `${URL_PATHS.CARTS}/${id}`;
    return httpClient
      .delete<ReportCart>({ url: urlPath })
      .then((response) => {
        return new ReportCart(response);
      })
      .catch(async (error) => {
        throw Error(error);
      });
  }

  static async updateCart(reportCard: ReportCart): Promise<ReportCart> {
    const urlPath = `${URL_PATHS.CARTS}/${reportCard.id}`;
    return httpClient
      .put<ReportCart>({ url: urlPath, payload: reportCard })
      .then((response) => {
        if (response) {
          return new ReportCart(response);
        }
        return reportCard;
      })
      .catch(async (error) => {
        throw Error(error);
      });
  }
}
