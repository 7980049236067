// Generic
import React from "react";
// Styles
import "./infoFieldText.module.css";
import { InfoFieldText } from "compass-commons";

// Models

interface InfoFieldTextProps {
  infoFieldText: InfoFieldText;
}

const InfoFieldTextComponent = ({
  infoFieldText,
}: InfoFieldTextProps): JSX.Element => {
  const { label, text } = infoFieldText || {};
  return (
    <div
      key={`operation-info-field-details-${label}`}
      className="operation-info-field__details"
    >
      <label htmlFor={label}>{label}:</label>
      <p id={label}>{text}</p>
    </div>
  );
};

export default InfoFieldTextComponent;
