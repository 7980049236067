import React, { MutableRefObject, useEffect, useState } from "react";
import "./tableView.module.css";
import Spinner from "@msi/cobalt-react/spinner";
import { useTable, usePagination } from "react-table";
import {
  Box,
  Pagination,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow,
  TableSortLabel,
  useTheme,
} from "@mui/material";
import { visuallyHidden } from "@mui/utils";

interface TableViewProps {
  columns: Array<any>;
  data: Array<any>;
  isPaginated?: boolean;
  onRowClickCallback?: (event) => void;
  apiCallFunc?: ({ pageIndex, pageSize }, sortBy?, sortDirection?) => void;
  totalDataCount?: number;
  totalPageCount?: number;
  loading?: boolean;
  rowsPerPage?: number;
  hideColumns?: Array<string>;
  sortable?: boolean;
  excludeColumnsFromSorting?: string[];
  selectedRowId?: string;
  resultPanelRef?: MutableRefObject<any>;
}

type Order = "asc" | "desc";

const TableView = (props: TableViewProps): JSX.Element => {
  const {
    columns,
    data,
    onRowClickCallback,
    isPaginated,
    apiCallFunc,
    totalPageCount,
    totalDataCount,
    loading,
    rowsPerPage,
    hideColumns,
    sortable,
    excludeColumnsFromSorting,
    selectedRowId,
    resultPanelRef,
  } = props;

  const {
    getTableProps,
    headerGroups,
    prepareRow,
    page,
    pageCount,
    gotoPage,
    nextPage,
    previousPage,
    state: { pageIndex, pageSize },
  } = useTable(
    {
      columns,
      data,
      initialState: {
        pageIndex: 0,
        pageSize: rowsPerPage,
        hiddenColumns: hideColumns,
      },
      manualPagination: !!apiCallFunc,
      pageCount: totalPageCount,
    },
    usePagination
  );

  const DEFAULT_BUTTON_HEIGHT = 180;
  const computeMaxHeight = () => {
    return resultPanelRef.current.clientHeight - DEFAULT_BUTTON_HEIGHT;
  };

  const [orderBy, setOrderBy] = useState(null);
  const [order, setOrder] = useState<Order>(null);
  const [tableMaxHeight, setTableHeight] = useState(computeMaxHeight());

  useEffect(() => {
    function handleResize() {
      setTableHeight(computeMaxHeight());
    }

    window.addEventListener("resize", handleResize);

    if (orderBy && order && sortable) {
      if (apiCallFunc) {
        apiCallFunc({ pageIndex, pageSize }, orderBy, order);
      }
    }
  }, [order, orderBy]);

  const sortHandler = (column) => {
    setOrderBy(column.Header);
    if (order === "asc") {
      setOrder("desc");
    } else {
      setOrder("asc");
    }
  };

  useEffect(() => {
    if (apiCallFunc) {
      apiCallFunc({ pageIndex, pageSize });
    }
  }, [apiCallFunc, pageIndex, pageSize]);

  const pageActions = (event) => {
    if (event.currentTarget?.innerText) {
      gotoPage(Number(event.currentTarget.innerText) - 1);
    }

    if (event.currentTarget.ariaLabel === "Go to next page") {
      nextPage();
    }

    if (event.currentTarget.ariaLabel === "Go to last page") {
      gotoPage(pageCount - 1);
    }

    if (event.currentTarget.ariaLabel === "Go to previous page") {
      previousPage();
    }

    if (event.currentTarget.ariaLabel === "Go to first page") {
      gotoPage(0);
    }
  };

  const TablePaginationActions = () => {
    const theme = useTheme();

    return (
      <div
        style={{
          flexShrink: 0,
          marginLeft: theme.spacing(2.5),
        }}
        data-cy="results-table-pagination-actions"
      >
        <Pagination
          count={Math.ceil((totalDataCount || data.length) / pageSize)}
          onChange={pageActions}
          showFirstButton
          showLastButton
          page={pageIndex + 1}
        />
      </div>
    );
  };

  return (
    /* eslint-disable react/jsx-props-no-spreading */
    <>
      {loading ? (
        <div
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          <Spinner size="large" />
        </div>
      ) : (
        data?.length > 0 && (
          <>
            <div className="table-wrapper" data-cr="de-table-wrapper">
              <TableContainer
                className="base-table"
                sx={{ maxHeight: tableMaxHeight }}
              >
                <Table
                  stickyHeader
                  aria-label="sticky table"
                  {...getTableProps()}
                >
                  <TableHead>
                    {headerGroups.map((headerGroup) => (
                      <TableRow {...headerGroup.getHeaderGroupProps()}>
                        {headerGroup.headers.map((column) => {
                          return column.hideHeader ? null : (
                            <TableCell
                              {...column.getHeaderProps({
                                style: {
                                  minWidth: column.minWidth,
                                  width: column.width,
                                },
                              })}
                            >
                              {sortable &&
                              !excludeColumnsFromSorting.includes(
                                column.Header
                              ) ? (
                                <TableSortLabel
                                  active={orderBy === column.Header}
                                  direction={
                                    orderBy === column.Header ? order : "asc"
                                  }
                                  onClick={() => {
                                    sortHandler(column);
                                  }}
                                >
                                  {column.render("Header")}
                                  {orderBy === column.Header ? (
                                    <Box component="span" sx={visuallyHidden}>
                                      {order === "desc"
                                        ? "sorted descending"
                                        : "sorted ascending"}
                                    </Box>
                                  ) : null}
                                </TableSortLabel>
                              ) : (
                                <div>{column.render("Header")}</div>
                              )}
                            </TableCell>
                          );
                        })}
                      </TableRow>
                    ))}
                  </TableHead>
                  <TableBody style={{ background: "white" }}>
                    {page.map((row) => {
                      prepareRow(row);
                      return (
                        <TableRow
                          data-cy="results-table-row"
                          {...row.getRowProps(
                            selectedRowId && selectedRowId === row?.values?.id
                              ? {
                                  style: {
                                    backgroundColor: "#DBDCDD",
                                  },
                                }
                              : {}
                          )}
                          onClick={() => onRowClickCallback(row)}
                        >
                          {row.cells.map((cell) => {
                            return (
                              <TableCell
                                {...cell.getCellProps({
                                  style: {
                                    minWidth: cell.minWidth,
                                    width: cell.width,
                                  },
                                })}
                              >
                                {cell.render("Cell")}
                              </TableCell>
                            );
                          })}
                        </TableRow>
                      );
                    })}
                  </TableBody>
                </Table>
              </TableContainer>
              {isPaginated && (
                <>
                  <Table>
                    <TableBody>
                      <TableRow>
                        <TablePagination
                          rowsPerPageOptions={[]}
                          count={totalDataCount || data.length}
                          rowsPerPage={pageSize}
                          page={pageIndex}
                          onPageChange={() => {
                            // console.log(`new page${pageIndex}`);
                          }}
                          SelectProps={{
                            inputProps: { "aria-label": "rows per page" },
                            native: true,
                          }}
                          ActionsComponent={TablePaginationActions}
                        />
                      </TableRow>
                    </TableBody>
                  </Table>
                </>
              )}
            </div>
          </>
        )
      )}
    </>
  );
};

TableView.defaultProps = {
  isPaginated: true,
  loading: false,
  rowsPerPage: 10,
  hideColumns: [],
  sortable: false,
  excludeColumnsFromSorting: [],
  selectedRowId: null,
  onRowClickCallback: () => null,
  apiCallFunc: () => null,
  totalDataCount: null,
  totalPageCount: null,
  resultPanelRef: null,
};

export default TableView;
