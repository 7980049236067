// General
import { useEffect, useState } from "react";
// Services
import DataExplorerService from "../services/DataExplorerService";
// Models
import { IncidentActivationsGroupDTO } from "../models/incidents/ActivationsGroup";

const useActivations = ({
  incidentId,
  stepId,
  pageIndex,
  pageSize,
}: {
  incidentId: string;
  stepId: string;
  pageIndex: number;
  pageSize: number;
}): {
  loading: boolean;
  error: boolean;
  data: IncidentActivationsGroupDTO[] | null;
} => {
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(false);
  const [data, setData] = useState<IncidentActivationsGroupDTO[] | null>(null);

  useEffect(() => {
    setLoading(true);
    DataExplorerService.getActivationsGroupList(incidentId, stepId, {
      pageSize,
      pageIndex: pageIndex || 0,
    })
      .then((response) => {
        setData(response);
        setError(false);
      })
      .catch(() => {
        setError(true);
      })
      .finally(() => {
        setLoading(false);
      });
  }, [pageIndex, pageSize]);

  return { loading, error, data };
};

export default useActivations;
